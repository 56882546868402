<template>
  <div>
    <div>
      <v-btn class="my-5" block color="green lighten-2" dark @click="tomarFoto"> FOTO</v-btn>  
      <input id="camera" ref="camera" style="visibility:hidden;display:none" type="file" accept="image/*" @change="onChangeFoto" capture="camera" />
    </div>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'


export default {
  name: 'CameraAPP',
  data() {
    return {
    }
  },
  props: {
      nCantidadFotos: {
        type: Number,
        default: 100
      },
      nFotosTomadas: {
        type: Number,
        default: 0
      },
    },
  methods: {
    onChangeFoto (e) {
        const myFile = e.target.files[0]
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1080,
          useWebWorker: true
        }
        imageCompression(myFile, options).then(compressedFile => {
          this.getBase64FromFileObject(compressedFile).then( sBase64 => {
            this.$emit('fotoCapture', {NombreFoto: myFile.name , Base64: sBase64})
          })
        }).catch(error => {
          alert(`Su dispositivo presenta problemas con la cámara ${error.message}`)
        })
    },
    async getBase64FromFileObject (fileObject) {
      return new Promise((resolve) => {
        var reader = new FileReader()
        reader.onloadend = (evt) => {
          var image = new Image()
          image.onload = () => {
            resolve(evt.target.result)
          }
          image.src = evt.target.result
        }
        reader.readAsDataURL(fileObject)
      })
    },
    tomarFoto() {
      if(this.nFotosTomadas < this.nCantidadFotos){
        this.$refs.camera.click()
      }else{
        this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgMaxFotos.replace('XXX',this.nCantidadFotos) )
        this.$store.dispatch('notificaciones/ShowNotificacion',true) 
      }
      
    },
  },
}
</script>

<style lang="scss" scoped>

</style>