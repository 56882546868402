<template>
<v-container fluid class="cont-pagina">
  <v-row class="no-gutters">
    <v-col cols="12">
      <h3 class="titulo-contendor">Tus tickets</h3>
    </v-col>
  </v-row>
  <v-row class="no-gutters">
    <v-col cols="12">
      <div class="pa-2 back-gray border-radius__a">
        <v-text-field
          v-model="sBuscador"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          clearable
          @click:clear="onClear()"
        ></v-text-field>
        <v-btn @click="onBuscar" color="btncolor" dark small class="my-1"> <v-icon left>mdi-magnify</v-icon> Buscar </v-btn>
        <v-data-table
          v-model="aItemSeleccionado"
          :headers="headers"
          :items="aData"
          single-select
          item-key="ticket"
          :items-per-page.sync="nRegistroPagina"
          :page.sync="nPagina"
          :mobile-breakpoint='nVistaTabla'
          class="table-general"
          hide-default-footer
          disable-sort
          >
          <template v-slot:top >
            <v-container v-if="$vuetify.breakpoint.xs">
              <v-row class="d-flex justify-end align-center">
                <span class="color-primary text-size-05">Cambiar visualización</span>
                <v-btn :color="nVistaTabla == 0 ? 'btncolor': 'gray'" icon dense small @click="nVistaTabla = 0" class="mx-1"><v-icon>mdi-table-large</v-icon></v-btn>
                <v-btn :color="nVistaTabla != 0 ? 'btncolor': 'gray'" icon dense small @click="nVistaTabla = 600"><v-icon>mdi-card-text-outline</v-icon></v-btn>
              </v-row>
            </v-container>
            <v-divider></v-divider>
          </template>
           <!-- CELDAS DEL GRID item tienen el dato a mostrar -- formatHeader tienen la info de la cabecera -- field.value tienen el nombre del campo-->
          <template v-slot:[`item.${field.value}`]="{ item }" v-for="(field, indexEncabezado) in headers" >
            <div :key="indexEncabezado">
              <verMasTexto :sTexto="item[`${field.value}`]" />
            </div> 
          </template>
          <!-- CELDA DE ACCIONES -->
          <template v-slot:item.acciones="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="onCargarDetalle(item.idRequerimiento)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
        <!-- FOOTER -->
        <div class="text-center pt-1 ">
          <v-pagination
            dense
            small
            v-model="nPagina"
            :length="nPaginas"
            :total-visible="10"
            :page="nPagina"
            tile
            class="mx-auto"
          ></v-pagination>
          <div class="cont-item-pag">
            <p class="ma-0 text-min text-primary text-registros">No. Registro</p>
            <v-select style="max-width: 80px;" class="text-min text-primary select-registros" 
            v-model="nRegistroPagina" :items="aItemsPagina" 
            dense outlined hide-details height="20px" >
            </v-select>
          </div>
        </div>
      </div>
    </v-col>
    <!-- MODAL DEL DETALLE -->
    <v-dialog v-model="bDetalleTicket" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle del ticket #{{ nIdSeleccionado }}</span>
        </v-card-title>
        <v-card-text class="pa-2 text-size-05">
          <v-container class="under-sec-rounded pa-2 mb-1" v-if="aDataDetalle.length == 0">
            <v-row>
              <v-col cols="12" class="text-center">
                <p class="ma-0">Ticket sin gestión. <br>No hay información para mostrar</p>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else class="under-sec-rounded pa-2 mb-1" v-for="(oDetalle , i) in aDataDetalle" :key="'detalle-'+i">
            <v-row class="no-gutters">
              <v-col cols="12">
                <p class="ma-0 mb-1"><strong>Fecha:</strong> {{oDetalle.Fecha}} </p>                     
              </v-col>
              <v-col cols="12">
                <p class="ma-0 mb-1"><strong>Estado:</strong> {{oDetalle.Estado}} </p>                     
              </v-col>
              <v-col cols="12">
                <p class="ma-0 mb-1"><strong>Solicitante:</strong> {{oDetalle.gestor}} </p>                     
              </v-col>
              <v-col cols="12">
                <p class="ma-0 mb-1"><strong>Descripción:</strong></p> 
                <verMasTexto :sTexto="oDetalle.Comentario" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" class="mx-auto" small dark @click="bDetalleTicket = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <ModalMensaje v-model="oModalMensaje.bModalVisible" :sMensaje="oModalMensaje.sMensaje" :nTipo="oModalMensaje.nTipo"/>
</v-container>
</template>
<script>
import mxFuncionesBasicas from "@/mixins/FuncionesBasicas.js";
import { mapGetters } from 'vuex';
import verMasTexto from "@/components/verMasTexto.vue"

export default {
  name: 'ConsultarTicket',
  mixins: [mxFuncionesBasicas],
  components:{verMasTexto},
  data() {
    return {
      sBuscador: '',
      aItemsPagina       : [5,10, 20, 50],       // indica los item de numero de registros por pagina 
      nRegistroPagina: 5,
      nPagina: 1,
      nPaginas: 0,
      aItemSeleccionado: [],
      nIdSeleccionado: '',
      nVistaTabla: 600,
      oModalMensaje: {
        bModalVisible: false,
        sMensaje: 'Menseje de prueba',
        sTitulo: '',
        nTipo: 1
      },
      headerProps:{
        sortByText: "Filtrar por"
      },
      headers: [
        {
          text: '#Ticket',
          align: 'start',
          sortable: false,
          value: 'idRequerimiento',
        },
        { text: 'Fecha', value: 'Fecha_insert_requerimiento' },
        { text: 'Solicitante', value: 'NombreSolicitante' },
        { text: 'Sede', value: 'Sede' },
        { text: 'Descripción', value: 'Requerimiento' },
        { text: 'Acciones', value: 'acciones', sortable: false },
      ],
      aData: [],
      headersDetalle: [ 
        {texto: 'Estado', valor:'estado'},
        {texto: 'Fecha', valor:'estado'},
        {texto: 'Descripción', valor:'estado'},
        {texto: 'Usuario Gestor', valor:'estado'},
      ],
      aDataDetalle: [],
      bActiveText: false, // Estado del botón ver más en el texto
      bDetalleTicket: false // Estado del modal del detalle del ticket
    }
  },
  watch:{
    nRegistroPagina () {
      this.onCargarInfo()
    },
    nPagina () {
      this.onCargarInfo()
    },
    sBuscador(){
      if(this.sBuscador === null){
        this.sBuscador = ''
      }
    }
  },
  methods: {
    onClear(){
      this.sBuscador = ''
      this.aData = []
      this.onCargarInfo()
    },
    onBuscar() {
      if(this.nPagina == 1){
        this.onCargarInfo()
      }else{
        this.nPagina = 1
      }
      this.$firebase.analytics().logEvent('PQRConsultaTicket', { Buscar:1})
    },
    async onCargarInfo() {
      const sParams = `nRegistroPagina=${this.nRegistroPagina}&nPagina=${this.nPagina}&nCliente=${this.getoPqrInfo.idCliente}&app=${this.$options.name}&sBuscador=${this.sBuscador}`
      const oResponse = await this.$axiosServices.getConfiguration('/pqr', false, sParams)

      try{
          if(oResponse.nStatusCode==200 || oResponse.nStatusCode==220){
            this.aData = oResponse.aData.aData
            this.nPaginas = oResponse.aData.nPagina
          }else{
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = oResponse.sMessage
          }
        }catch(e) {
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = 'Reintente nuevamente o verifique su conexión a internet'
        }
    },
    async onCargarDetalle(nTicket) {
      this.nIdSeleccionado = nTicket
      const sParams = `app=${this.$options.name}`
      const oResponse = await this.$axiosServices.getConfiguration('/pqr/' + nTicket, false, sParams)
      try{
          if(oResponse.nStatusCode==200 || oResponse.nStatusCode==220){
            this.aDataDetalle = oResponse.aData
            this.bDetalleTicket = true
          }else{
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = oResponse.sMessage
          }
        }catch(e) {
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = 'Reintente nuevamente o verifique su conexión a internet'
        }
    },
    verItem(item) {
      this.editedIndex = this.aData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
  },
  mounted() {
    this.$store.dispatch('saveLinkStatus', true);
    this.$store.dispatch('saveLinkHeader', 'pqriniciotickte');
    this.onCargarInfo()
  },
  computed: {
    ...mapGetters(['getoPqrInfo'])
  }
}
</script>
<style lang="scss">
tbody{
  tr{
    td{
      font-size: 11px!important;
      min-height: 30px!important;
    }
  }
}
.v-icon.v-icon{
  font-size: 18px;
}
.boton-mas{
  color: blue;
}
.cont-item-pag{
  transform: scale(0.8);
}
.text-registros{
  font-size:12px;
  text-align: left;
}
</style>