<template>
  <div class="text-center">
    <v-dialog
      v-model="value"
      width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Confirmación </v-card-title>
        <v-card-text class="text-h4"> Desea Salir de la Aplicación ?</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="SalirAplicacion">
            SI
          </v-btn>
          <v-btn
            color="error"
            text
            @click="Cancelar">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosServices from "@/services/axiosServices.js"
import { mapGetters } from 'vuex'

  export default {
    name:"LogOut",
    props: {
      value : {
        type: Boolean,
        default: false 
      },
    },
    data () {
      return{
      }
    },
    methods: {
      SalirAplicacion() {
        const oBody =  {
          imei : this.getimei,
          userID : this.getnIduser ,
          app: 'app',
        }
        axiosServices.postConfiguration('/logend', oBody, true )
        this.$store.dispatch("setClearLogin")
        this.$emit('input', false)
        this.$router.replace({name: 'LoginHome'}) 

      },
      Cancelar(){
        this.$emit('input', false)
      },
    },
    computed: {
      ...mapGetters(['getimei', 'getnIduser'])
    },
  }
</script>

<style lang="scss" scoped>

</style>