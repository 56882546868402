
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa' || 'mdi',
  },
  theme: {
      dark: false,                // lo inicio en false para despues poderlo manejar en la intanciacioón de vue
      options: {
          customProperties: true,
        },
      themes: {
        light: {
          primary   : '#0E69B0',   // indigo
          primaryb   : '#0E69B0',   // indigo
          secondary : '#424242',   // gris oscuro 
          accent    : '#82B1FF',   // azul clarito 
          error     : '#FF5252',   // rojo
          info      : '#2196F3',   // azul oscurito
          success   : '#4CAF50',   // verde
          warning   : '#FFC107',   // amrillo
          btncolor  : '#0E69B0',   // Azul de los botones
          headforms : '#D2EDF2',   // Azul cabecera formularios modal
          titlesdark: "#054278",   // Azul oscuro titulos y barra de progreso
          titleslight : "#0E69B0",  //Azul claro titulos formularios
          backgrey  : "#F8F8F8",   // gris clarito fondo
          ntitlesdark : "#012362",  // Nuevo color de titlulos oscuro
          backwhite : "#FFF",
          backlogin : "#FFF",
          textosgraylight : "#717171", //
          blue_light:"#F5FFFF"
        },
        dark:{
          backwhite : "#161E33",
          primary   : '#4075D9',
          backlogin : "#040b1d",
          primaryb   : '#FFF',   // indigo
          textosgraylight : '#FFF'
        }
      },
    },

});
