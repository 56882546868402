import Loader from '@/components/Loader.vue'
import HoraFecha from "@/utils/Hora_Fecha.js"

export default {
    
    components: {
      Loader
    },
    template: `<loader v-model="isCargando"/>`,
    data () {
      return {
        isCargando: false,
      }
    },
    methods: {
      onFormatearTexto(sTexto){
        return sTexto.replaceAll(`'`,'`')
      },
      getValidarCampos(aCampos, oRespuesta) {
        let bError = false
        let sMensaje = ''
        aCampos.forEach(oItem => {
          if(oItem.obl==1){
            if(oRespuesta[oItem.nombre] !== undefined){
              if(oRespuesta[oItem.nombre] == null || oRespuesta[oItem.nombre] == '' || oRespuesta[oItem.nombre] === undefined){
                //if(oItem.tipo=='anexos'){
                  
                //}else{
                  bError = true
                  sMensaje += '- Falta por diligenciar el campo &&&&'.replace('&&&&','<b>' + oItem.label + '</b>')+ ' <br>'
                //}
              }
            }else{
              bError = true
              sMensaje += '- Falta por diligenciar el campo &&&&'.replace('&&&&','<b>' + oItem.label + '</b>')+ ' <br>'
            }
          }
        })
        if(bError){
          this.$swal.fire({
            title: 'Campos incompletos',
            icon: 'info',
            html:'<p>'+sMensaje+'</p>',
            confirmButtonText: 'Aceptar',
          })
        }
  
        return bError
      },
      async onSubirAnexos(aArchivos, sPrefijoArchivo){
        aArchivos.forEach(oItem => {
          // valido que no sea un file list con mas de un elemento 
          if(oItem.length === undefined){
            // Lo recorro de nuevo
            Object.keys(oItem).forEach( nKey => {
              const nTipoAnexo = (oItem[nKey].type.includes('image')) ? 1 : 2
              const formData = new FormData()
              formData.append('file', oItem[nKey])
              formData.append('Fecha', HoraFecha.getNow())
              formData.append('app', 'Foto')
              formData.append('size', oItem[nKey].size)
              formData.append('filename',`${sPrefijoArchivo}_${oItem[nKey].name}`)
              this.$axiosServices.postFile('/archivo', formData, true, nTipoAnexo)
            })
          } else{
            //console.log(oItem[0])
            const nTipoAnexo = (oItem[0].type.includes('image')) ? 1 : 2
            const formData = new FormData()
            formData.append('file', oItem[0])
            formData.append('Fecha', HoraFecha.getNow())
            formData.append('app', 'Foto')
            formData.append('size', oItem[0].size)
            formData.append('filename',`${sPrefijoArchivo}_${oItem[0].name}`)

            this.$axiosServices.postFile('/archivo', formData, true,nTipoAnexo)
          }
        })
      },
      onFormatearAnexos(aAnexos, sPrefijoArchivo) {
        let aGaleria = [];
        // recorro los Anexos para sacar todos los nombres de las anexos que tenga y dejarlos como [ {Foto : "NombreArchico"}]
        aAnexos.forEach(oItem => {
          // valido que no sea un file lizt con mas de un elemento 
          if(oItem.length === undefined){
            // Lo recorro de nuevo
            Object.keys(oItem).forEach( nKey => {
              aGaleria.push({ Foto : sPrefijoArchivo + '_' + oItem[nKey].name , TipoAnexo: (oItem[nKey].type.includes('image')) ?'1' : '2' })
            })
          } else {
            aGaleria.push({ Foto : sPrefijoArchivo + '_' + oItem[0].name, TipoAnexo: (oItem[0].type.includes('image')) ?'1' : '2'})
          }
        })
    
        return aGaleria
    
      },
    }
  }