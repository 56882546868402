<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
<!-- RESUMEN DE SUBFORMULARIO -->
  <v-card class="my-1" min-width="300" outlined v-for="(subitem, index) in subitems" :key="index" >
    <v-list-item three-line>
      <v-list-item-content>
        <v-card-actions color="primary" light>
        <div class="overline mb-4">Registro Número {{index+1}} </div>
        <v-spacer></v-spacer>
          <!-- <v-btn rounded outlined icon color="success" @click="edit(index)"><v-icon >mdi-pencil</v-icon></v-btn> -->
          <v-btn rounded outlined icon color="error" @click="remove(index)"><v-icon >mdi-delete</v-icon></v-btn>
        </v-card-actions>
        <RespuestaCampos
          :resdatos="subitem">
        </RespuestaCampos>
      </v-list-item-content>
    </v-list-item>
  </v-card>
<!-- BOTON PARA AGREGAR SUBFORMULARIO -->
  <v-card class="my-1">
    <v-card-actions color="primary" light>
        <v-btn color="primary" block dark @click="showAddSubitem = true">
          <v-icon small class="icon-rigth mr-1">mdi-plus </v-icon> Agregar registro
        </v-btn> 
    </v-card-actions>
  </v-card>
<!-- MODAL CON SUBFORMULARIO -->
  <v-dialog v-model="showAddSubitem"  persistent  :overlay="false" max-width="500px" >
    <v-card>
      <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Registro</v-toolbar-title> <v-spacer></v-spacer>
          <v-btn icon dark @click="showAddSubitem = false"><v-icon>mdi-window-close</v-icon></v-btn>
      </v-toolbar>
      <div class="py-3 px-3">
          <GeneradorCampos
              :key="IndexSubform"
              :schema="camposSub"
              v-model="aSubFormData">
          </GeneradorCampos>
          <v-card-actions class="justify-center">
              <v-btn @click="cancelar" small outlined color="primary"> Cancelar <v-icon small right>mdi-close</v-icon></v-btn>
              <v-btn @click="save" small outlined color="primary"> Guardar <v-icon small right>mdi-cloud-download-outline </v-icon></v-btn>
          </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog ref="mostrarErrores"
    v-model="mostrarErrores"
    persistent
    max-width="300">
    <v-card>
      <v-card-title class="text-h6">Advertencia! </v-card-title>
      <v-card-text> Falta diligenciar los siguientes campos obligatorios: </v-card-text>
      <v-list-item v-for="(error, index) in aErrores" :key="index">
        <v-list-item-content class="my-0">
          <v-list-item-title  v-html="error"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="mostrarErrores = false" >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</ContenedorCampos>
</template>

<script>

import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import RespuestaCampos from '@/components/dinamicos/RespuestaCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import DinamicosUtils from '@/utils/DinamicosUtils.js'

export default {
  name: 'Item',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
    RespuestaCampos
  },
  data () {
    return {
      aSubFormData : [],
      subitems : [],
      showAddSubitem : false,
      IndexSubform : 0,
      mostrarErrores : false,
      aErrores: [],
    }
  },
  mounted () {
  },
  methods: {
      cancelar(){
          this.showAddSubitem = false
      },
      // edit(index){
      //     this.showAddSubitem = true
      // },
      save(){
        if(!this.validarObligatorios(this.aData[0])){
          // this.IndexSubform++ 
          return
        }

        if(!this.selectValue){
            this.selectValue = []
        }
        this.aSubFormData.map(element=>{
          element.IdGrupo = (this.subitems.length + 1)
        })
        this.subitems.push({...this.aSubFormData})
        this.selectValue.push({...this.aSubFormData})     // Empleo esta forma para evitar la relacion por referencia y hago esto para pasar la info
        this.IndexSubform++
        this.aSubFormData = []      // Borro el registro para tenerlo listo para el proximo item en blanco
        this.showAddSubitem = false
      },
      remove (index) {
        this.subitems.splice(index, 1)
        this.selectValue.splice(index, 1)
      },
      validarObligatorios(schema){  // esta funsion no la puedo volver una utiliadad porque debo alterar variables de este componente.
        // valida los campos obligatorios se regresa 
        // el que sabe quienes son obligatorios es el esquema el cual se recorre y se compara con la respuesta
        this.aErrores = []   // limpio par iniciar
        schema.forEach( (campo, index) => {
          if(campo.Obligatorio == "1"){
            const infoCampo =  DinamicosUtils.getInfo_aData(this.aSubFormData, campo.idFORM_DINAMICOS_Preguntas)
            // de acuerdo al tipo valido si esta diligenciado por valor o textorespuesta o Detalle
            if((infoCampo == undefined) &&  (campo.idFORM_DINAMICOS_Tipos_campos != '13' && campo.idFORM_DINAMICOS_Tipos_campos != '15')){
              // El campo no esta ni diligenciado se marca como error de una 
              schema[index].error = true
              this.aErrores.push(campo.Pregunta)
              return
            } else {
              schema[index].error = false
            }
            switch (campo.idFORM_DINAMICOS_Tipos_campos) {
              case '3':  // Foto
              case '4':  // Multiselect
              case '12': // Firma
                // por Detalle es un array por lo cual length > 0
                if(infoCampo.Detalle.length == 0){ 
                  schema[index].error = true
                }

              break;
              case '5':  // Checkboox 
                // por valor ValorRespuesta  pero no hago nada por que si es obligatorio se llena por defaul en 0
                schema[index].error = false
              break;
              case '13':  // Informacion
              case '15':  // Imagen
                schema[index].error = false
              break;
              default:
                // por TextoRespusta
                if(infoCampo.TextoRespuesta == '' ){
                  schema[index].error = true
                }
              break;
            }
          }
        })
        if(this.aErrores.length >0 ){
          this.mostrarErrores = true
          return false
        } else {
          return true
        }

    },
  },
  computed: {
    camposSub(){
      let aux_info = []
      this.aData[0].forEach( campo => {
        aux_info.push(
          {
            "Modifica_A_ID" : "0",
            "Obligatorio" : campo.Obligatorio,
            "Pregunta" : campo.Pregunta,
            "TipoCampo" : DinamicosUtils.getTipoCampo(campo.idFORM_DINAMICOS_Tipos_campos),
            "Valor" : campo.Valor,
            "idFORM_DINAMICOS_Formulario" : campo.idFORM_DINAMICOS_Formulario,
            "idFORM_DINAMICOS_GRUPOS" : "0",
            "idFORM_DINAMICOS_Nombre_Formulario" : campo.idFORM_DINAMICOS_Nombre_Formulario,
            "idFORM_DINAMICOS_Preguntas" : campo.idFORM_DINAMICOS_Preguntas,
            "idFORM_DINAMICOS_Tipos_campos" : campo.idFORM_DINAMICOS_Tipos_campos,
            "error" : this.IndexSubform == 0 ? false : campo.error, 

          }
        )
      })
      return aux_info
    }
  },
  watch: {
  },

}
</script>

<style lang="scss" scoped>

</style>
