// Se crea una constante llamada database que es un arraeglo de objetos con 
// { tabla: "NombreTabla", info: [Arreglo de la info] }

const state = () => ({
  dataBase : [],

})

const getters = {
  getdataBase: ( state ) => {
    return state.dataBase;
  },
} 

const mutations = {
  SALVARTABLA : ( state, {tablaName, info} ) => {
    const index_tabla = state.dataBase.findIndex( table => table.tablaName == tablaName)
    if(index_tabla < 0) {
     state.dataBase.push({ tablaName, info});
    } else {
     state.dataBase[index_tabla] = {tablaName , info}
    }  
  },
  DELETETABLA : ( state, tablaName ) => {
    const index_tabla = state.dataBase.findIndex( table => table.tablaName == tablaName)
    if(index_tabla >= 0) {
      state.dataBase.splice(index_tabla,1);
    }
  },

}

const actions = {
  salvarTabla( {commit}, {tablaName, info} ) {
    commit( "SALVARTABLA", {tablaName, info});
  },
  deleteTabla( {commit}, tablaName ) {
    commit( "DELETETABLA", tablaName);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}