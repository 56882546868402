<template>
<v-container grid-list-xs fluid fill-height pb-5 class="general-container">
    <v-layout row wrap justify-center align-center >
      <v-flex xs12 sm5 md7 mt-2 v-if="!this.$vuetify.breakpoint.xs">
      </v-flex>
      <v-flex xs12 sm7 md5>
        <v-layout justify="center" align="center">
          <v-col  align-self="center">
            <v-form ref="form" v-model="validacion" >
              <v-card class="card" :elevation="this.$vuetify.breakpoint.xs ? 5 : 0">
                <v-card-title>
                  <v-col xs12 class="logo">
                    <img src="/img/icons/logo-company.png">
                  </v-col>
                </v-card-title>
                <v-card-text > 
                  <v-col sm="10" md="10" lg="10" xl="10" class="label">
                    <span class="titleslight--text font-weight-regular">Usuario</span>
                    <span class="titleslight--text font-weight-regular ml-1">*</span>
                  </v-col>
                  <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                    <v-text-field 
                      ref="username" 
                      v-model="username" 
                      append-icon="fa fa-user-circle" 
                      :rules="[rules.requerido]"   
                      outlined   
                      dense   
                      required>
                    </v-text-field>
                  </v-col>
                  <v-col sm="10" md="10" lg="10" xl="10" class="label">
                    <span class="titleslight--text font-weight-regular">Contraseña</span>
                    <span class="titleslight--text font-weight-regular ml-1">*</span>
                  </v-col>
                  <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                    <v-text-field 
                      ref="password"
                      v-model="password" 
                      :append-icon="showPass ? 'fa fa-eye' : 'fa fa-eye-slash'" 
                      :rules="[rules.requerido, rules.Min5, rules.Max20]" 
                      :type="showPass ? 'text' : 'password'" 
                      name="input-10-1" 
                      @click:append="showPass = !showPass" 
                      outlined 
                      dense 
                      required>
                    </v-text-field>
                  </v-col>
                  <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                    <v-btn  class="font-weight-regular" block dark @click="login(false)"  size="large"  color="primary" :disabled="!(validacion && bErrorPermisos) || this.getbCargandoUrl"> 
                       Ingresar
                    </v-btn>
                  </v-col>
                </v-card-text>
                <v-col sm="12" md="12" lg="12" xl="12">               
                  <v-footer absolute class="footer">
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold">&copy; Derechos Reservados TechBoss - V {{versionPWA}}</div>
                    <v-spacer></v-spacer>
                  </v-footer>
                </v-col>
              </v-card>
            </v-form>
          </v-col>
        </v-layout>

      </v-flex>
    </v-layout>
    <!-- dialogo para deslogueo -->
    <v-dialog
      v-model="bDesloguear"
      persistent
      max-width="290px">
      <v-card-title class="grey lighten-2">
        Cerrar Sesión Iniciada
      </v-card-title>
      <v-card-text class="white">
        <p>Este usuario se encuentra logueado en otro Dispositivo. Sí procede a loguearse perdera toda la informacion del anterior dispositivo</p>
      </v-card-text>
      <v-card-actions class="white">
      <v-row d-flex class="py-3">
        <v-flex xs12 class="btn-deslog">
          <v-btn @click="bDesloguear=false"  size="large" color="red" > 
              No cerrar sesión
          </v-btn>
          <v-btn @click="login(true)" size="large" max-width="100%" color="primary" :disabled="disbleCerrarSesion" > 
              <span>
              Cerrar la sesion <br> del otro Dispositivo
              </span>
          </v-btn>
        </v-flex>
      </v-row>  
      </v-card-actions>
    </v-dialog>  
</v-container>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import { mapGetters } from 'vuex'
import GeneradorIMEI from '@/utils/GeneradorIMEI.js'
import GPSLocation from '@/utils/GPSlocation.js'
import MD5 from '@/utils/md5.js'
import HoraFecha from "@/utils/Hora_Fecha.js"

import { version } from "../../package.json"


export default {
  name: "LoginHome",
  data() {
    return {
      // alert : true,  
      rules: {
        requerido : value => !!value || 'Campo Requerido',
        Min5 : value => value.length >= 5 || 'Longitud Mínima 5 Caracteres',
        Max20 : value => value.length <= 20 || 'Longitud Máxima 20 Caracteres',
      },
      username : '',
      password : '',
      showPass : false, 
      validacion : false,
      oLocation : {},
      bErrorPermisos : true,
      bDesloguear : false,
      disbleCerrarSesion : false,
      versionPWA : version
      // iapp : '',
    }
  },
  async mounted () {
    try {
      this.oLocation = await GPSLocation.getCurrentPosition()

    } catch (error) {
        this.$store.dispatch('notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicación. Valide y refresque la Pantalla' )
        this.$store.dispatch('notificaciones/ShowNotificacion',true)
        this.bErrorPermisos = false
    }
    
    // if(typeof IApp !== 'undefined'){ // eslint-disable-line
    //   this.iapp = IApp.getTexto() // eslint-disable-line
    // } else {
    //   this.iapp = 'No IApp'
    // }

     
  },
  methods: {
    async login(force) {

      this.disbleCerrarSesion = true 
      let battery =0
      try {
          battery = await navigator.getBattery()
        
      } catch (error) {
          battery =0
      } 
      const IMEI = await GeneradorIMEI.getIMEI()
      this.$store.dispatch('setimei', IMEI)


      const oBody = {
          imei : IMEI,
          app : "ActivityLogin",
          username : this.username.trim(),
          password : MD5.encripMD5(this.password.trim()),
          token : this.gettokenFirebase,
          lat : this.oLocation.coords.latitude,  // Falta perdir lat long
          lng : this.oLocation.coords.longitude,
          bateria : `${battery.level*100}`,
          timegps : HoraFecha.getNow(),
          versionapp : "1.7.7",
          islogin : force,
          iduser : "0",
          origen : "APP"
        }
        const oLogueado = await axiosServices.postConfiguration((!force ? '/login' : '/loginForce'), oBody, false)
        
        switch (oLogueado.nStatusCode) {
          case 200:  // OK
          case 215:  // usuario logueado OK
            this.bDesloguear =false
            await this.registrarLogin(oLogueado.aData,this.username.trim())
            this.clear()
          break;
          case 400:  // Error
          case 402:  // Usuario contraseña no coinciden 
          case 403:  // Usuario no autorizado
          case 419:  // Su permir no tienen permisos app
          case 501:  // Error en BD
            this.bDesloguear =false
          break;
          case 210:  // Usuario ya loqueado (La APP unifcada y el Vue Seif manejan esta respuesta)
          case 418:  // Usuario ya loqueado (Cuando la api SeifModulos se apague, esta respuesta se puede eliminar)
            this.bDesloguear = true
          break
          default:
          break
        }

        this.disbleCerrarSesion = false 


    },
    async registrarLogin(aData,sLogin){
      // todo lo dejo en el Store 
      await this.$store.dispatch('setsUserName', sLogin)
      await this.$store.dispatch('setLogin', true)
      await this.$store.dispatch('setRegistrarLogin', aData)
      this.$router.replace({name: 'Home'})
    },
    clear(){
      this.username = '' 
      this.password = '' 
      this.showPass = false, 
      this.validacion = false 
      this.oLocation = {} 
      this.bErrorPermisos = true 
      this.bDesloguear = false 
    },

  },
  computed: {
      ...mapGetters(['gettokenFirebase', 'getbCargandoUrl'])
    },
}
</script>

<style lang="scss" scoped>
@import '../css/global.scss';
.btn-deslog{
  text-align: center;
  button{
    margin: 5px 0px;
  }
}

</style>
