export default {
  getNow() {
    const hoy = new Date
    return `${hoy.getFullYear()}-${this.onConvertirFecha(hoy.getMonth() + 1)}-${this.onConvertirFecha(hoy.getDate())} ${this.onConvertirFecha(hoy.getHours())}:${this.onConvertirFecha(hoy.getMinutes())}:${this.onConvertirFecha(hoy.getSeconds())}`         
  },
  getNowFile() {
    const hoy = new Date
    return `${hoy.getFullYear()}${hoy.getMonth()<10?'0':''}${(hoy.getMonth() + 1)}${hoy.getDate()<10?'0':''}${hoy.getDate()}${hoy.getHours()<10?'0':''}${hoy.getHours()}${hoy.getMinutes()<10?'0':''}${hoy.getMinutes()}${hoy.getSeconds()<10?'0':''}${hoy.getSeconds()}${hoy.getMilliseconds()}`         
  },
  getDia() {
    const hoy = new Date
    return `${hoy.getFullYear()}-${this.onConvertirFecha(hoy.getMonth() + 1)}-${this.onConvertirFecha(hoy.getDate())}`         
  },
  getHora() {
    const hoy = new Date
    return `${this.onConvertirFecha(hoy.getHours())}:${this.onConvertirFecha(hoy.getMinutes())}:${this.onConvertirFecha(hoy.getSeconds())}`         
  },
  onConvertirFecha(nNumero){
    return (nNumero<=9) ? '0' + nNumero : nNumero
  },
  onSumarMinutosHora(sHora, nMinutos){
    const dFechaFin = new Date(0,0,0,sHora.split(":")[0],sHora.split(":")[1],sHora.split(":")[2],0)
    dFechaFin.setMinutes(dFechaFin.getMinutes()  +nMinutos)
    return `${this.onConvertirFecha(dFechaFin.getHours())}:${this.onConvertirFecha(dFechaFin.getMinutes())}:${this.onConvertirFecha(dFechaFin.getSeconds())}`         
  }


}