<template>
  <div class="text-center">
    <v-btn
      color="primary"
      dark
      class="btn-rounded"
      @click="Firmar">
      Firmar
      <v-icon right size="20">mdi-draw</v-icon>
    </v-btn>   
    <v-dialog
      v-model="showFirma" 
      fullscreen
      persistent 
      :overlay="false"
      max-width="500px"
      >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Firme por favor:</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showFirma = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="py-3 px-3">
          <VueSignaturePad
            id="signature"
            ref="signaturePad"
            width="100%"
            height="450px"
            :options="options"/>
          <v-text-field
            label="Nombre"
            v-model="Nombre"
            color="primary darken-2"
            required
          ></v-text-field>

          <v-card-actions class="justify-center">
            <v-btn  @click="cancelar" small outlined color="primary"> Cancelar <v-icon small class="icon-rigth">mdi-close</v-icon> </v-btn>
            <v-btn  @click="clear" small outlined color="primary"> Borrar <v-icon small class="icon-rigth">mdi-delete-outline </v-icon></v-btn>
            <v-btn  @click="save" small outlined color="primary"> Guardar <v-icon small class="icon-rigth">mdi-cloud-download-outline </v-icon> </v-btn>
            </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name:"siganature",
  data() {
    return {
      Nombre : '',
      showFirma : false,
      options : {
        penColor: "black",
        onBegin: this.onBegin, 
        backgroundColor: "white"
      },
    }
  },
  methods: {
    onBegin () {
      this.$refs.signaturePad.resizeCanvas()
    },    
    Firmar(){
      this.showFirma = true
    },
    clear() {
      // this.$refs.signaturePad.undoSignature()  // Borra el ultimo trazo
      this.$refs.signaturePad.clearSignature()
      this.Nombre = ''
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      const nombre = this.Nombre

      if(isEmpty){
        alert("La Firma es obligatoria")
        return
      }

      if(nombre == ''){
        alert('Nombre Obligatorio')
        return
      }

      this.$emit('firmado',{ isEmpty, data , nombre  } )
      this.cancelar()
    },
    cancelar() {
      this.clear()
      this.showFirma = false
    },
  },
}
</script>

<style lang="scss" scoped>
  #signature {
    border: solid 2px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #4bc5e8, #9f6274);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  .icon-rigth{
    left: 2px;
  }
</style>