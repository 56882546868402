<template>
  <v-dialog
    v-model="bModalCargando"
    min-width="500px"
    transition="dialog-transition"
    persistent
  >
  <v-card>
    <v-card-text class="pa-3 mensaje d-flex justify-center align-center">
        <div class="color-primary">Cargando...</div> 
        <img src='/img/loading.png' class="icon-load-app">
    </v-card-text>
  </v-card>
    
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalCargando',
  data() {
    return {
      bModalCargando: false,
    }
  },
  watch:{
    getModalCargandoMo(newValor){
      this.bModalCargando = newValor
    },
  },
  computed:{
    ...mapGetters(['getModalCargandoMo'])
  }
}
</script>
<style lang="scss" scoped>
.cont-icono{
  background: #32BEA6;
  padding: 10px;
  border-radius: 50%;
}
.mensaje{
  color: var(--v-primary-base);
}
.texto-mensaje{
  line-height: 1.1em;
  font-size: 1.5em;
  color: var(--v-primary-base);
}
</style>