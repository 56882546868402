<template>
  <!-- Componente tipo modal para ampliar fotos y texto 
  Tiene dos props para cargar los textos e imagen
  Se debe declarar para su uso el con model el v-model
  -->
  <v-dialog v-model="modelShow" persistent :overlay="false" max-width="500px"
  transition="dialog-bottom-transition" >
      <v-card class="pa-3 justify-center align-center text-center">
      <v-card-title 
        class="text-medium color-primary-b justify-center text-size-2"
        >{{ titulo }}</v-card-title>
      <div class="py-3 px-3">
          <img :src="imagen" width="150px">
      </div>
      <v-card-text
        class="text-medium justify-center text-size-1"
        >{{ texto }}
      </v-card-text>
      <v-btn
        class="btn-primary margin-auto text-transform-initial text-semibold"
        color="primary"
        dark
        @click="$emit('click', false)"
        >
        Aceptar
      </v-btn>
      </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name:'DialogMensaje',
    model:{
      prop: 'modelShow',
      event: 'click'
    },
    props: {
      modelShow: Boolean,
      titulo:{
        default:''
      },
      imagen:{
        default:''
      },
      texto:{
        default:''
      }
    },
    data() {
      return {
      }
    },
    methods: {
    },
    
  }
</script>

<style lang="scss" scoped>
// corrige un margin negativo que está declarado en algún lugar del universo de vuetify 
.row {
  margin: 0!important;
}
.cont-resp-texto{
  font-size: 0.8em;
}
.under-sec{
  padding: 2px!important;
}
.btn-img{
  border-radius: 10px;
  img{
    width: 100%;
  }
}

.margin-auto{
  margin: 0 auto;
}
</style>