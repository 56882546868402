

// Se crea una constante llamada offline donde gurado todas las peticiones axios que fallan 
// esta constante es un array y se trabajara metiendo la ultima peticion al final y se borrara si todo sale bien.
// En el momento de desocupar el arreglo se hace de la mas vieja a la mas nueva.

const state = () => ({
  aAxiosOffline : [],

})

const getters = {
  getaAxiosOffline: ( state ) => {
    return state.aAxiosOffline;
  },
  getNumeroOffline: ( state ) => {
    let aux_cont = 0
    state.aAxiosOffline.forEach( elemento => {
      if(elemento.method != 'NOEnviar'){
        aux_cont++
      }
    })
    return aux_cont
  },
} 

const mutations = {
  SALVAR_REQUEST : ( state, request ) => {

      // con el fin de borrar siempre que llega sin cambiar la filosofia pero ahorra campo en data, guardo un objeto con method = NOEnviar
      if(request.method != "get" && !request.url.includes("/public/login?origen")){
        state.aAxiosOffline.push(request)
      } else {
        state.aAxiosOffline.push({method : 'NOEnviar'})
      }
  },
  DELETE_REQUEST : ( state ) => {
      state.aAxiosOffline.pop();   // Borro el ultimo elemento
  },

}

const actions = {
  salvar_request( {commit}, request ) {
    commit( "SALVAR_REQUEST", request);
  },
  delete_request( {commit} ) {
    commit( "DELETE_REQUEST");
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}