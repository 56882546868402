import axios from 'axios'
import params from '@/params/params.js'
import store from '@/store/index.js'
// import NProgress from 'nprogress'
import Base64ToFile from '@/utils/Base64ToFile.js'
import APPutils from '@/utils/APPutils'

const apiClient = axios.create({
  baseURL: `${params.path}`,
})

// Implementamos un watcher para siempre escuchar los cambios de la sUrlBase y actualizar 
// la baseURL de la instancia de axios. (tener en cuenta que la Url es dinamica porque se consutla a conexiones)
const urlBaseWatcher = store.watch(
  (state, getters) => getters.getsUrlBase,
  (newVal, oldVal) => {
    //console.log(`sUrlBase cambió de:${oldVal}, a:${newVal}`);
    apiClient.defaults.baseURL = newVal;
  }
);

let oConfigBase = {
  headers: {
    'Content-Type'  : 'application/json',
  }
}

let oConfigFile = {
  headers: {
    'Content-Type'  : 'multipart/form-data',
  }
}
// Se crean interceptore para poner la barra de progreso
apiClient.interceptors.request.use(request => { // Called on request
  // NProgress.start()
  store.dispatch('saveModalCargandoMo', true)
  // console.log('peticion Axios', request)
  // primero valido si estoy con APP, si estoy -> envio la info al celular
    if(!store.state.isAPP){
      store.dispatch('axiosOffline/salvar_request', request)
    }  
  return request
})

apiClient.interceptors.response.use(response => { // Called on response
  // NProgress.done()
  store.dispatch('saveModalCargandoMo', false)
  store.dispatch('setisOnline', true) 
  store.dispatch('axiosOffline/delete_request')
  // console.log('Repuestas nivel arriba', JSON.stringify(response))

  return response
},(error)=>{
  // console.log ('error respuetas axios',error.response.data.nStatusCode)
  // NProgress.done()
  store.dispatch('saveModalCargandoMo', false)
  if (error.response == undefined) {
    if(!store.state.isAPP){
      store.dispatch('notificaciones/SetMensaje','Notificación: Error, en la respuesta. Puede tener problemas de red' )
      store.dispatch('notificaciones/ShowNotificacion',true) 
    } 

    if(error.message == 'Network Error'){
      store.dispatch('setisOnline', false)      // Indico que estamos offline
    } else {
      store.dispatch('setisOnline', true) 
      store.dispatch('axiosOffline/delete_request')
    }
    //console.log(error.response)

    return {
      nStatusCode: 500,
      sMessage: 'Problemas de conexión a la red, intente nuevamente el envio de información'
    }
  }

  switch(error.response.data.nStatusCode){
    case 401: // error de Token 
        store.dispatch('notificaciones/SetMensaje','Notificación: '+ error.response.data.sMessage )
        store.dispatch('notificaciones/ShowNotificacion',true)    
        store.dispatch('setLogin', false).then(() => {
          if(store.state.isAPP){
            APPutils.Notificacion_token_error()
          } else {
            window.localStorage.clear()
            sessionStorage.clear()
            let URLdomain = window.location
            window.location.href = `${URLdomain.origin}`
          }
      })
    break
    default:
      store.dispatch('notificaciones/SetMensaje','Notificación: ' + error.response.data.sMessage )
      store.dispatch('notificaciones/ShowNotificacion',true) 
    break  
  }
  store.dispatch('setisOnline', true) 
  store.dispatch('axiosOffline/delete_request')

  
  return error.response
})

const sOrigen = 'APP'

export default {
  // Metodos get
  getConfiguration(sURL, bBearer = false, sParams = '', sTokenTmp = '') {
    let  sComplementURL = `?origen=${sOrigen}&subdominio=${store.state.sSubDominio}&v=${new Date().getTime()}&imei=${store.state.imei}&app=app`
    
    if (bBearer) {
      if(sTokenTmp != ''){
        oConfigBase.headers.Authorization = `Bearer ${sTokenTmp}`;
      }else{
        oConfigBase.headers.Authorization = `Bearer ${store.state.token}`
      }
      
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    return apiClient.get(`${sURL}${sComplementURL}` , oConfigBase)
    .then(aGetData => {
      try {
      return aGetData.data
      } catch (error) {
        return []
      }
       
   });
  },
  // Metodos POST
  postConfiguration(sURL, oBody, bBearer = false, sParams = '') {
    let sComplementURL = `?origen=${sOrigen}&subdominio=${store.state.sSubDominio}&imei=${store.state.imei}&v=${new Date().getTime()}`;
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    oBody.nIdUser = store.state.nIduser
    return apiClient.post(`${sURL}${sComplementURL}`, oBody, oConfigBase)
    .then(aResponsePOST => {
       try {
       return aResponsePOST.data
       } catch (error) {
         return []
       }
        
    });
  },  
  // Metodo PUT
  putConfiguration(sURL, oBody, bBearer = false, sParams = '' ) {
    let sComplementURL = `?origen=${sOrigen}&subdominio=${store.state.sSubDominio}&v=${new Date().getTime()}`;
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    oBody.nIdUser = store.state.nIduser
    return apiClient.put(`${sURL}${sComplementURL}`, oBody, oConfigBase)
    .then(aResponsePUT => {
      try {
      return aResponsePUT.data
      } catch (error) {
        return []
      }
       
     });
  },
  // Metodo POST par offline
  postOffline(sURL, oBody, bBearer = false) {
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return apiClient.post(sURL, oBody, oConfigBase)
    .then(aResponsePOST => {
      try {
      return aResponsePOST.data
      } catch (error) {
        return []
      }
     }) 
  },
  // Metodo PUT par offline
  putOffline(sURL, oBody, bBearer = false) {
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return apiClient.put(sURL, oBody, oConfigBase)
    .then(aResponsePUT => {
      try {
      return aResponsePUT.data
      } catch (error) {
        return []
      }
    })
     
  },
  // Metodo para post file 
  postFile(sURL, file, bBearer = false,nTipoAnexo= 0) {
    let sComplementURL = `?origen=${sOrigen}&subdominio=${store.state.sSubDominio}&imei=${store.state.imei}&v=${new Date().getTime()}&nTipoAnexo=${nTipoAnexo}`;

    if (bBearer) {
      oConfigFile.headers.Authorization = `Bearer ${store.state.token}`;
    }

    return apiClient.post(`${sURL}${sComplementURL}`, file, oConfigFile)
    .then(aResponsePOST => {
      try {
      return aResponsePOST.data
      } catch (error) {
        return []
      }
    }) 
  },
  // Metodo para enviar fotos como files 
  async postFoto(NombreArchivo, Base64){
    const file = await Base64ToFile.Base64toFile(Base64, NombreArchivo,'image/png')

    let formData = new FormData()

    formData.append('file', file)

    return this.postFile('/subirarchivo', formData, true)

  },
  async enviarOffline( request, index){
    // request : estoda la data que esta alamacenada en un bojeto del arreglo de aAxiosOffline
    // index : numero de registro a enviar y borrar
    let rsp = null 
    switch (request.method) {
      case 'get':
      case 'NOEnviar':
          // Borro de una 
          store.state.axiosOffline.aAxiosOffline.splice(index,1)
      break
      case 'put':
       rsp = await this.putOffline(request.url, request.data, true)

      break  
      case 'post': 
       rsp =  await this.postOffline(request.url, request.data, true)
      break    
    }
    if((rsp.nStatusCode > 100) && (rsp.nStatusCode < 300) ){
      // se guardo o se hizo bien el put - se borra el registro
      store.state.axiosOffline.aAxiosOffline.splice(index,1)
    } else {
      // si respuesta es negativa me mete otro registro offline por lo cual lo borro.
      store.dispatch('axiosOffline/delete_request')
    }
  },
  async enviarAllOffline(){
    // recorreo el arreglo de reques alamacenados y los envio.
    // console.log('Enviando offline')


   
    store.state.axiosOffline.aAxiosOffline.forEach( async (request, index) => {
      this.enviarOffline( request, index)
    })

  },

}