<template>
  <v-card  v-model="value">
  </v-card>
</template>
<script>
// import axiosServices from '@/services/axiosServices.js'
export default {
  name:'ModalMensaje',
  components: { },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    sTitulo: {
      type: String,
    },
    sMensaje: {
      type: String,
    },
    nTipo: {
      type: Number,
      default: 1 // 1 - Exitoso | 2 - Informativo | 3 Error 
    }
  },
  watch:{
    value(){
      if(this.value)this.openModal(this.nTipo)
      //console.log(this.nTipo)
    }
  },
  methods: {
    onCerrarModal () {
      this.bMostrarModal = false
      this.$emit('input', false)
    },
    openModal(val){
      let swalWithBootstrapButtons = null
      switch (val) {
        case 1:
          swalWithBootstrapButtons = this.$swal.mixin({
          customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn-prueba'
            },
            buttonsStyling: true
          })
          swalWithBootstrapButtons.fire({
            title: ""+this.sTitulo+"",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          }).then((result) => {
            this.onCerrarModal()
            if (result.isConfirmed) {
              this.$emit('respuesta', true)
            } else {
              this.$emit('respuesta', false)
            }
          })
        break;
        case 2 :
          swalWithBootstrapButtons = this.$swal
          swalWithBootstrapButtons.fire({
            title: ""+this.sTitulo+"",
            icon: 'info',
            html:'<p>'+this.sMensaje+'</p>',
            confirmButtonText: 'Aceptar',
          }).then(() => {
            this.onCerrarModal()
          })
        break  
        case 3 :
          swalWithBootstrapButtons = this.$swal
          swalWithBootstrapButtons.fire({
            title: ""+this.sTitulo+"",
            icon: 'error',
            html:'<p>'+this.sMensaje+'</p>',
            onfirmButtonText: 'Aceptar',
          }).then(() => {
            this.onCerrarModal()
          })
        break  
      }
    }
  },
  computed: {
    onTipoMensaje(){
      return (this.nTipo==1) ? 'mdi-check-bold' : ((this.nTipo==2) ? 'mdi-information-variant' : 'mdi-close-thick')
    },
    
  }
}
</script>
<style lang="scss" scoped>
.cont-icon{
  width: 50px;
  height: 50px;
  margin:0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--v-primary-base);
}
</style>