<template>
    <v-snackbar
      class="text-center"
      v-model="getsnackbar"
      :timeout="timeout">
      <p>
      {{ getmensajeNotificacion }}
      </p>
      <div class="text-center mt-2">
      <v-btn
        class="margin-auto"
        color="red"
        text
        outlined
        @click="$store.dispatch('notificaciones/ShowNotificacion',false)">
        CERRAR
      </v-btn>
      </div>
    </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "Notificacion",
  data() {
    return {
      text    : '',
      timeout : -1,
    }
  },
  computed: {
    ...mapGetters('notificaciones',['getsnackbar','getmensajeNotificacion'])
  },
}
</script>
<style lang="scss" scoped>
.v-application p{
  margin-bottom: 0px;
}
</style>