<template>
  <v-dialog
    v-model="isLecturaQR"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
   <v-card
   class="py-0 text-center"
   >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="isLecturaQR = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-size-2">{{ $STR.ttlCodigoQR}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <!--vue-qr :text="sQR" :callback="test" :qid="sQR"></vue-qr-->
          <vue-qr :text="sQR" :qid="sQR"></vue-qr>
     </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  name: 'MostrarQR',
  components: { VueQr },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sQR: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLecturaQR: false,
    };
  },
  mounted() {
    this.isLecturaQR = this.value;
  },
  methods: {
    /*test(dataUrl, id) {
      //console.log(dataUrl, id);
    },*/
  },
  watch: {
    isLecturaQR(NewValor) {
      this.$emit('input',NewValor)
    },
    value(newValor){
      this.isLecturaQR = newValor
    }
  },
};
</script>
