<template>
<div>
  <v-layout row wrap justify-center align-center px-2 py-1>
    <v-flex xs12 my-0 mb-1 pr-3>
      <span :class="clase">{{titulo}}{{isObligatorio == '1' ? '**' : '' }}</span>
    </v-flex>
    <v-flex xs12 mt-1>
      <slot></slot>
    </v-flex>
  </v-layout>
  </div>
</template>

<script>
  export default {
    name:"ContenedorCampos",
    props: {
      titulo: {
        type: String,
        default: 'Sin Titulo'
      },
      isObligatorio: {
        type: String,
        default: "0"
      },
      error: {    // Se emplea para aindica que existe un error y pone en rojo el titulo del campo, en especial cuando es obligatorio y no se llena
        type: Boolean,
        default: false
      }          

    },
    data() {
      return {
      }
    },
    computed: {
      clase() {
        return {
          'ntitlesdark--text' : !this.error,
          'red--text' : this.error 
        }
      }
    },
    
  }
</script>

<style lang="scss" scoped>
// corrige un margin negativo que está declarado en algún lugar del universo de vuetify 
.row {
  margin: 0!important;
}
.bdred{
  border: solid red;
}

</style>