<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-text-field v-if="sCorreo==''"
    ref="txt_correo"
    v-model="sCorreo"
    outlined
    dense
    clearable
    append-outer-icon="mdi-check"
    @click:append-outer="addCorreo"
  ></v-text-field>
  <v-text-field 
    v-else
    ref="txt_correo"
    v-model="sCorreo"
    :rules="[rules.email, rules.minValue]"
    outlined
    dense
    clearable
    append-outer-icon="mdi-check"
    @click:append-outer="addCorreo"
  ></v-text-field>
  <v-list class="my-0">
    <v-list-item
      v-for="(correo, key) in selectValue"
      :key="key"
      class="my-n5"
    >
      <v-list-item-title v-text="correo.Correo" class="my-0"></v-list-item-title>
      <v-list-item-action class="my-0">
        <v-btn icon color="red" @click="delCorreo(key)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'Correo',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {
      sCorreo : '',
      rules :{
        requerido: value => !!value || 'Indique un correo valido',
        minValue: value => value.length >= 6 || 'El correo debe tener mínimo 6 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Correo Invalidado'
        },
      }
    }
  },
  mounted () {
    // this.selectValue = []
  },
  methods: {
    addCorreo(){
      if(!this.selectValue) {
        this.selectValue = []
      }
      if(this.$refs.txt_correo.validate() && this.sCorreo!=''){
        this.selectValue.push({Correo : this.sCorreo})
        this.sCorreo = ''
      }
    },
    delCorreo(index){
      this.selectValue.splice(index,1)
    }

  },
  computed: {

  },

}
</script>

<style lang="scss" scoped>
</style>
