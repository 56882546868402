<template>
  <div>
    <v-layout row wrap justify-center align-center>
      <v-flex xs12 sm9 md6 mb-3 mt-2>
        <Convenciones></Convenciones>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center >
      <v-flex xs12 px-2>
        <h3>Estado de las Bases de Datos</h3>
      </v-flex>
      <v-flex xs12 sm6 md4 py-2 px-2>
        <v-select
        label="Seleccione su sede de trabajo"
        v-model="idSedeBase"
        :items="getaSedes"
        item-text="Nombre_Sede"
        item-value="idSede"
        @change="guardarIDSede"
        dense
        outlined>
        </v-select>
      </v-flex>
      <v-flex xs12 sm12>
        <v-layout row wrap px-1 pb-10  align-center justify-center v-if="idSedeBase > 0">
          <v-progress-linear v-if="Descargando" indeterminate color="blue lighten-1" ></v-progress-linear>
          <DescargaTB v-for="(oElemento, key) in aTablasPermisos" :key="key" 
            :index="key"
            @descargado="cambioIcono"
            :oTabla="oElemento">
          </DescargaTB>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- <div class="container-float">
    </div> -->
    <v-btn color="primary" class="btn-float mr-15" dark fixed medium fab @click="allDonwnload" :disabled="(!(getnIDSedeBase > 0) || Descargando) ">
      <v-icon dense size="20px">fas fa-cloud-download-alt</v-icon>
    </v-btn>
  </div>
  
</template>

<script>
// primero toca tener la sede, la cual se obtienen en el loging
// OJO validar persmisos que vienen en el loging
// debe guardar el perfil del usuario y descargar la data en caso de cambio de usuario si y solo si el perfil es diferente.

  import ValidarBD from "@/utils/ValidarBD.js"
  import DescargaTB from "@/components/descargadb/DescargaTB.vue"
  import Convenciones from "@/components/descargadb/Convenciones.vue"
  import DinamicosUtils  from "@/utils/DinamicosUtils.js"
  import { mapGetters } from 'vuex'


  export default {
    name: "DescargaDB",
    components: {
      DescargaTB,
      Convenciones,
    },
    data() {
      return {
        aTablasPermisos : [],
        idSedeBase : 0,
        ndescargasSolicitadas : 0,        // creo esta variabble para controlar que no se pueda descargar all varias veces seguidas
      }
    },
    mounted () {
      this.aTablasPermisos = []
      this.aTablasPermisos = ValidarBD.getBaseDatosDescarga()
      
      //valido si ya se tienen una sede seleccionada para pre cargarla
      if(this.getnIDSedeBase > 0){
        this.idSedeBase = this.getnIDSedeBase
      }
    },
    methods: {
      getTablas( tablaName) {
        // esta funciona valida si la tabla existe o no dentro de la db storage
        return this.getdataBase.findIndex( table => table.tablaName == tablaName)
      },
      guardarIDSede() {
        this.$store.dispatch('setnIDSedeBase', this.idSedeBase)
      },
      cambioIcono(oInfo){
        this.aTablasPermisos[oInfo.index].icono = oInfo.icono    // Descargado 
      },
      allDonwnload() {
        // recorro todos los registros de aTablasPermisos
        let idIcono = 1
        this.ndescargasSolicitadas = 0
        this.aTablasPermisos.forEach( async (aTabla, index) => {
          this.aTablasPermisos[index].icono = idIcono   // Descargando

          // ValidarBD.descargarTB(aTabla.tabla).then( respuesta => {
          //   if(respuesta.sStatus == 'success'){
          //     // todo salio bien
          //     idIcono = 2   // Descargado
          //     this.$store.dispatch('dataBase/salvarTabla', {tablaName : this.aTablasPermisos[index].tabla, info: respuesta.aData})
          //     ValidarBD.TB_actualizarFechadescarga(this.aTablasPermisos[index].equiMovil, respuesta.aData.length)
          //     this.aTablasPermisos[index].nRegistros = respuesta.aData.length
          //   }else{
          //     // fallo
          //     idIcono = 3   // Errror
          //   }
          //   this.aTablasPermisos[index].icono = idIcono   // Descargando


          // })
          this.ndescargasSolicitadas++
          const respuesta = await ValidarBD.descargarTB(aTabla.tabla)
            if(respuesta.sStatus == 'success'){
              // todo salio bien
              idIcono = 2   // Descargado
              this.$store.dispatch('dataBase/salvarTabla', {tablaName : this.aTablasPermisos[index].tabla, info: respuesta.aData})
              ValidarBD.TB_actualizarFechadescarga(this.aTablasPermisos[index].equiMovil, respuesta.aData.length)
              this.aTablasPermisos[index].nRegistros = respuesta.aData.length
            }else{
              // fallo
              idIcono = 3   // Errror
            }
            this.aTablasPermisos[index].icono = idIcono   // Descargando



          // Si descargue Form_Dinamicos_reguntas  toca descargar los valores adicionales.
          if( aTabla.tabla == 'formularios-dinamicos/preguntas') {
            await DinamicosUtils.getPreguntasValores()
          }

          idIcono = 1
          this.ndescargasSolicitadas--
        })

      }
    },
    computed: {
      Descargando (){
        return this.ndescargasSolicitadas !== 0 
      },

      ...mapGetters(['getaPermisos', 'getaActualizaciones','getaSedes', 'getnIDSedeBase']),
      ...mapGetters('dataBase',['getdataBase']),
    },
    
  }
</script>

<style lang="scss" scoped>
.row{
  margin: 0!important;
}
h3{
    font-size: 1em;
    color: #0E3143;
    font-weight: 500;
}
.btn-float{
  z-index: 5;
  bottom: 45px;
  right: 0px;
}

</style>

