import Vue from "vue"
import Vuex from "vuex"
// Persistencia de storage en 
import  createPersistedState  from  'vuex-persistedstate'

// Modulos
import notificaciones from '@/store/modulos/notificaciones.js'
import firebasenotificaciones from '@/store/modulos/firebasenotificaciones.js'
import dataBase from '@/store/modulos/dataBase.js'
import axiosOffline from '@/store/modulos/axios_offline.js'
import dinamicos from '@/store/modulos/dinamicos.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login               : false,
    token               : '',
    sSubDominio         : '',          // subdominio del cliente         
    tokenFirebase       : '',
    aPermisos           : [],          // Arreglo de persisos con los modulos que pueden trabajar el usuario.
    nIduser             : 0,           // en nuemro del id del usuario
    sNombreUsuario      : '',
    nIdPerfil           : 0,
    sPerfil             : '',
    nIdCiudad           : 0,
    aActualizaciones    : [],
    aSedes              : [], 
    nIDSedeBase         : 0,
    imei                : '',
    isOnline            : true,        // Indica si la aplicacion esta en linea o no
    sQR                 : '',
    sUserName           : '',
    isAPP               : false,       // Con esta variable indico si estoy corriendo dentro de la APP
    sLinkStatus         : false,
    sLinkHeader         : '',
    bMenuBlock          : false,
    oPqrInfo            : {},
    oModalCargandoMo    : false,
    nIdFormulario       : 0,
    sUrlBase            : '',         // Url Base del API para hacer las peticiones
    bCargandoUrl        : false,      // Indica si esta activo el proceso de consulta de la Url Base
  },
  getters: {
    getDomain : (state) => {
      return state.sSubDominio
    },
    getLogin : (state) => {
      return state.login
    },
    getToken : (state) => {
      return state.token
    },
    gettokenFirebase : (state) => {
      return state.tokenFirebase
    },
    getaPermisos : (state) => {
      return state.aPermisos
    },
    getnIduser : (state) => {
      return state.nIduser
    },
    getsNombreUsuario : (state) => {
      return state.sNombreUsuario
    },
    getnIdPerfil : (state) => {
      return state.nIdPerfil
    },
    getsPerfil : (state) => {
      return state.sPerfil
    },
    getaActualizaciones : (state) => {
      return state.aActualizaciones
    },
    getnIdCiudad : (state) => {
      return state.nIdCiudad
    },
    getaSedes : (state) => {
      return state.aSedes
    },
    getnIDSedeBase : (state) => {
      return state.nIDSedeBase
    },
    getimei : (state) => {
      return state.imei
    },
    getisOnline : (state) => {
      return state.isOnline
    },
    getsQR : (state) => {
      return state.sQR
    },
    getsUserName: (state) => {
      return state.sUserName
    },
    getisAPP : (state) => {
      return state.isAPP
    },
    getLinkStatus : (state) => {
      return state.sLinkStatus;
    },
    getLinkHeader : (state) => {
      return state.sLinkHeader;
    },
    getbMenuBlock : (state) => {
      return state.bMenuBlock
    },
    getoPqrInfo : (state) => {
      return state.oPqrInfo
    },
    getModalCargandoMo : (state) => {
      return state.oModalCargandoMo
    },
    getIdFormulario : (state) => {
      return state.nIdFormulario
    },
    getsUrlBase : (state) => {
      return state.sUrlBase
    },
    getbCargandoUrl : (state) => {
      return state.bCargandoUrl
    },
  },
  mutations: {
    SETSUBDOMAIN: (state, value) => {
      state.sSubDominio = value
    },
    SETLOGIN: (state,value) => {
      state.login = value
    },
    SETTOKENFIREBASE: (state,value) => {
      state.tokenFirebase = value
    },
    SETAPERMISOS: (state,value) => {
      state.aPermisos = value
    },
    SETNIDUSER: (state,value ) => {
       state.nIduser = value 
    },
    SETSNOMBREUSUARIO: (state,value ) => {
       state.sNombreUsuario = value 
    },
    SETNIDPERFIL: (state,value ) => {
       state.nIdPerfil = value 
    },
    SETSPERFIL: (state,value ) => {
       state.sPerfil = value 
    },
    SETNIDCIUDAD: (state,value ) => {
       state.nIdCiudad = value 
    },
    SETaActualizaciones: (state,value ) => {
       state.aActualizaciones = value 
    },
    SETASEDES: (state,value ) => {
       state.aSedes = value 
    },
    SETNIDSEDEBASE: (state,value ) => {
       state.nIDSedeBase = value 
    },
    SETIMEI: (state,value ) => {
       state.imei = value 
    },
    SETREGISTRARLOGIN: (state, value) =>{
        state.aPermisos       = value.permisos      
        state.nIduser         = value.iduser        
        state.sNombreUsuario  = value.nombreusuario 
        state.nIdPerfil       = value.idperfil      
        state.sPerfil         = value.perfil        
        state.nIdCiudad       = value.idciudad      
        state.aActualizaciones = value.actualizaciones
        state.aSedes          = value.sedes      
        state.token           = value.sToken   
    },
    SETCLEARLOGIN: (state ) =>{
        state.aPermisos       = []     
        state.nIduser         = 0        
        state.sNombreUsuario  = "" 
        state.nIdPerfil       = 0      
        state.sPerfil         = ""        
        state.nIdCiudad       = 0      
        state.aActualizaciones = ""
        state.aSedes          = []      
        state.token           = ""  
        state.sUserName       = ""  
        state.login           = false 
    },
    SETISONLINE: (state,value ) => {
      state.isOnline = value 
    },
    SETSQR: (state,value ) => {
      state.sQR = value 
    },
    SETSUSERNAME: (state,value ) => {
      state.sUserName = value 
    },
    SETISAPP: (state,value ) => {
      state.isAPP = value 
    },
    SETLINKSTATUS: (state, value) => {
      state.sLinkStatus = value;
    },
    SETLINKHEADER: (state, value) => {
      state.sLinkHeader = value;
    },
    SETBMENUBLOCK: (state,value ) => {
      state.bMenuBlock = value 
    },
    SETOPQRINFO: (state, value) => {
      state.oPqrInfo.sNombre = value.sNombre
      state.oPqrInfo.sCorreo = value.sCorreo
      state.oPqrInfo.idCliente = value.idCliente
    },
    SETMODALCARGANDOMO: (state,value) => {
      state.oModalCargandoMo = value
    },
    SETIDFORMULARIO: (state,value ) => {
      state.nIdFormulario = value 
    },
    SETURLBASE: (state, value ) => {
      state.sUrlBase = value 
    },
    SETCARGANDOURLBASE: (state, value ) => {
      state.bCargandoUrl = value 
    },
  },
  actions: {
    saveSubDomain({commit}, value){
      commit ('SETSUBDOMAIN', value)
    },
    setLogin({commit}, value){
      commit ('SETLOGIN', value)
    },
    settokenFirebase({commit}, value){
      commit ('SETTOKENFIREBASE', value)
    },
    setaPermisos({commit}, value){
      commit ('SETAPERMISOS', value)
    },
    setnIDSedeBase({commit}, value){
      commit('SETNIDSEDEBASE', value)
    },
    setRegistrarLogin({commit}, value){
      commit('SETREGISTRARLOGIN', value)
    },
    setimei({commit}, value){
      commit('SETIMEI', value)
    },
    setisOnline({commit}, value){
      commit('SETISONLINE', value)
    },
    setsQR({commit}, value){
      commit('SETSQR', value)
    },
    setClearLogin({commit}){
      commit('SETCLEARLOGIN')
    },
    setsUserName({commit}, value){
      commit('SETSUSERNAME', value)
    },
    setisAPP({commit}, value){
      commit('SETISAPP', value)
    },
    saveLinkStatus({commit}, value){
      commit ('SETLINKSTATUS', value)
    },
    saveLinkHeader({commit}, value){
      commit ('SETLINKHEADER', value)
    },
    savebMenuBlock({commit}, value){
      commit('SETBMENUBLOCK', value)
    },
    setoPqrInfo({commit}, value){
      commit('SETOPQRINFO', value)
    },
    saveModalCargandoMo({commit},value){
      commit ('SETMODALCARGANDOMO', value)
    },
    savesPerfil({commit}, value){
      commit('SETSPERFIL', value)
    },
    saveIdFormulario({commit}, value){
      commit('SETIDFORMULARIO', value)
    },
    saveUrlBase({commit}, value){
      commit('SETURLBASE', value)
    },
    setCargandoUrlBase({commit}, value){
      commit('SETCARGANDOURLBASE', value)
    },
  },
  modules: {
    // para el manejo de modulos se debe tener en cuenta que toca anteceder el mnombre del modulo 
    // guia de uso https://vuex.vuejs.org/guide/modules.html#module-local-state

    notificaciones,
    firebasenotificaciones,
    dataBase,
    axiosOffline,
    dinamicos
  },
  // aquí agregamos la persistencia
	plugins: [createPersistedState()]
})