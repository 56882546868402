<template>
  <v-row>
    <v-dialog v-model="isModal" fullscreen hide-overlay transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="isModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $STR.ttlFotos }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(item, index) in aInfoFotos" :key="index">
              <v-card class="mx-auto card-rounded my-2" flat>
                <v-img
                  class="border-gray"
                  :src="item[sNombreCampo]"
                  height="200px"
                  width="200px"
                ></v-img>
              </v-card>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ListadoFotos",
  mixins: [],
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sNombreCampo: {
      type: String,
      defalt: "",
    },
    aInfoFotos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isModal: false,
    };
  },
  mounted() {
    this.isModal = this.value;
  },
  methods: {},
  watch: {
    value(NewValor) {
      this.isModal = NewValor;
    },
    isModal(NewValor) {
      this.$emit("input", NewValor);
    },
  },
};
</script>