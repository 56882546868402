<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-radio-group v-model="selectValue">
    <v-radio
      v-for="(info, key) in aOptions"
      :key="key"
      :label="info.DatoMostrar"
      :value="info.ID"
    ></v-radio>
  </v-radio-group>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js' 
import ValidarBD from '@/utils/ValidarBD.js'

export default {
  name: 'RadioButton',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {
      radioGroup: 1,
    }
  },
  mounted () {
    // Debo obtener los valores a mostra los cuales se guardan en la tabla local storage que vienen en Valor bajo la estructura
    // DatoMostrar: lo que se muestar -    Filtro: para filtrar   -   ID: mi ID   -   Indice: NO usado

    this.aOptions =  ValidarBD.getInfoTabla(this.aData)
  },
  methods: {
   
  },
  computed: {
    
  },

}
</script>

<style lang="scss" scoped>

</style>
