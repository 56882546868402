<template>
<div>
  <v-container grid-list-xs container md-6 v-if="!bErrorPermisos">
    <v-row no-gutters justify="center">
      <v-col cols="12" xs="12" sm="8" md="8" lg="6">
        <GeneradorCampos
          :schema="schema"
          v-model="aFormData"
          class="pb-10">
        </GeneradorCampos>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="center" class="mb-10 mt-n8">
      <v-col cols="6" xs="6" sm="4" md="4" lg="6" justify="center" align="center">
        <v-btn @click="cancelar" large dark color="primary" class="btn-primary btn-rounded">
          Cancelar <v-icon size="20" right>mdi-close</v-icon></v-btn>
      </v-col>  
      <v-col cols="6" xs="6" sm="4" md="4" lg="6" justify="center" align="center">
        <v-btn @click="save" large dark color="primary" class="btn-primary btn-rounded"  
        :loading="disabledGuardar"
        :class="{'no-click' : disabledGuardar }"
        >
          Enviar <v-icon size="20" right>mdi-cloud-download-outline </v-icon> </v-btn>
      </v-col> 
    </v-row>
    <v-dialog ref="confirmarSalida"
      v-model="ConfirmarSalida"
      persistent
      max-width="300">
      <v-card>
        <v-card-title class="text-h6"> Estas seguro de Cancelar? </v-card-title>
        <v-card-text> Perdera toda la información !! </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="ConfirmarSalida = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="closeActivity" >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog ref="mostrarErrores"
      v-model="mostrarErrores"
      persistent
      max-width="300">
      <v-card>
        <v-card-title class="text-h6">Advertencia! </v-card-title>
        <v-card-text> Falta diligenciar los siguientes campos obligatorios: </v-card-text>
        <v-list-item v-for="(error, index) in aErrores" :key="index">
          <v-list-item-content class="my-0">
            <v-list-item-title  v-html="error"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="mostrarErrores = false" >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-alert v-else
    color="red"
    icon="mdi-alert-outline"
    type="error"
  > Los permisos de GPS no son correctos </v-alert>

</div>
</template>

<script>
import DinamicosUtils from '@/utils/DinamicosUtils.js'
import axiosServices from '@/services/axiosServices.js'
import GPSLocation from '@/utils/GPSlocation.js'
import HoraFecha from "@/utils/Hora_Fecha.js"
import { mapGetters } from 'vuex'
import APPutils from '@/utils/APPutils.js'


export default {
  name:'Dinamicos',
  components: {
  },
  data() {
    return {
      schema: null,
      aFormData: [],          // esta variable se usa para ser llenada con cada campo en generadorCampos.
      ConfirmarSalida : false,
      mostrarErrores : false,
      aErrores: [],
      oLocation : {},
      aFotos : [],           // Arreglo para guardar las fotos o firmas que toca enviar -- se debe blanquear antes de llamar PreparaDataSend
      refrescar : 0,
      disabledGuardar : false,
      bErrorPermisos : false ,
      nIdFormulario: 0
    }
  },
  async mounted () {
    // limpio el aModificaA
    this.$store.dispatch("dinamicos/clearModificaA")
    if (Object.keys(this.$route.params).length > 0) {
      // Quiere decir que abrimos desde la WEB, por lo que obtenemos el id por el parametro :idFormulario de la Ruta
      this.nIdFormulario = this.$route.params.idFormulario
      this.schema = DinamicosUtils.getFormulario(this.nIdFormulario)
    }else{
      // Quiere decir que abrimos desde la APP, por lo que obtenemos el id del formulario desde el store, el cual se guarda en el router/index.js a traves
      // de la interface Javascript del WebView Android APPutils.getIdFormulario()
      this.nIdFormulario = this.getIdFormulario
      this.schema = DinamicosUtils.getFormulario(this.nIdFormulario)
    }
    await this.getGPSLocation();
    APPutils.onGuardarLog("mounted Dinamicos.vue ejecutado")
  },
  methods: {
    async getGPSLocation(){
      // valido que se tenga permisos de GPS para continuar
      try{
        this.oLocation = await GPSLocation.getCurrentPosition()
        this.bErrorPermisos = false
      }catch(e){
        if(e.message=='User denied Geolocation'){
          this.bErrorPermisos = true
        }
        // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
        APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
      } 

    },
    async save() {
      this.disabledGuardar = true
      // se debe validar los obligatorios
      if(! await this.validarObligatorios()){
       this.disabledGuardar = false
       return
      }

      // genear el envio -- seprando las fotos y preparando el resto.
      this.aFotos = []     // lo Blanqueo fotos antes de procesar a data

      if(this.getisAPP){
        this.$store.dispatch('setimei', APPutils.getIMEI() )
      }

      const sParams = `imei=${this.getimei}&app=PWA`

      //validamos que se tengan las coordenadas, de no tenerlas se muestra un mensaje y se intenta obtenerlas nuevamente
      if( !this.oLocation?.coords?.latitude || !this.oLocation?.coords?.longitude){
        this.getGPSLocation();
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo validar la ubicación GPS. Inténtelo nuevamente',
          showConfirmButton: false,
          timer: 5097
        })
        this.disabledGuardar = false
        return
      }

      const oBody = {
        imei : this.getimei,
        app : "ActivityFormulario",
        accion : "RegistroFormulario_Dinamico",
        Proyecto : this.PreparaDataSend(this.aFormData),
        idFormulario : this.nIdFormulario,
        Latitud : this.oLocation.coords.latitude,
        Longitud : this.oLocation.coords.longitude,
        Fecha : HoraFecha.getNow(),
      }

      const oRSP = await axiosServices.postConfiguration('/formularios-dinamicos', oBody, true, sParams )
      
      // envio las fotos que tego guardadas en aFotos si no estoy con APP
      if(!this.getisAPP){
        this.aFotos.forEach( async foto => {
          // se recorren objetos todos tienenl a foto en base64, pero si es firma tienen el nombre en Firma y si es foto el nombre esta en NombreFoto
          if(('NombreFoto' in foto)){
            axiosServices.postFoto(`${foto.NombreFoto}`, foto.Base64)
          }
          if(('Firma' in foto)){
            axiosServices.postFoto(`${foto.Firma}`, foto.Base64)
          }
        })
      }

      if(this.getisAPP) {
        try {
          if(!Object.prototype.hasOwnProperty.call(oRSP, 'nStatusCode')) {
            /* eslint-disable no-console */
            // console.log('PRUEBA OFFLINE')
            APPutils.enviarDataOffline(oBody)
            // console.log('PRUEBA DESPUES OFFLINE')

            this.disabledGuardar = false

          }else {
            if ((oRSP.nStatusCode == 200) || (oRSP.nStatusCode == 220)  ){
            APPutils.registro_OK()
          } else {
            APPutils.enviarDataOffline(oBody)
          }
          this.disabledGuardar = false
          }
          
        } catch(e) {
          /* eslint-disable no-console */
          // console.log(e)
          APPutils.onGuardarLog("Error al enviar formulario:"+e)
          APPutils.enviarDataOffline(oBody)
          this.disabledGuardar = false
        }
        

      } else {
        this.disabledGuardar = false
        switch (oRSP.nStatusCode) {
          case 200: {
            this.$swal({
              timer: 1500,
              icon: `${oRSP.sStatus}`,
              title: `${oRSP.sMessage}`,
              showConfirmButton: false
            })
            break
          }
          case 220: {
              this.$swal({
              timer: 1500,
              icon: 'info',
              title: `${oRSP.sMessage} # ${oRSP.aData}`,
              showConfirmButton: false
            })
            break
          }
        }
        this.salir()
      }
    },
    cancelar() {
      this.ConfirmarSalida = true
    },
    salir () {
      this.aFormData = []
      this.nIdFormulario = 0
      // Al finalizar o salir de un FD desde la APP, seteamos siempre en 0 el idFormulario del store para un nuevo uso. Esto porque el comportamiento de la APP es que
      // al finalizar un FD se cierra el activity limpiando la data, para que al ingresar nuevamente se vuelvan a cargar la PWA desde el inicio con la informacion respectiva.
      this.$store.dispatch('saveIdFormulario', 0)
      this.$router.replace('/ListaDinamicos')
    },
    PreparaDataSend(aDataSinFormato){
      // en esta funcion se prepara la data para el envio lo dejo como un metodo por que un Computado se ejecuta con cada cambio del aFormData
      const aData = []     // la inicializo en blanco
      aDataSinFormato.forEach( elemento => {
        // 1. Quitar el base 64 de las fotos(3) y de las firmas(12)
        if((elemento.TipoPregunta == "3") || (elemento.TipoPregunta == "12")){
          // abro Detalle y quito el Base64
          for(const indexFoto in elemento.Detalle){
            this.aFotos.push({...elemento.Detalle[indexFoto]})
            delete elemento.Detalle[indexFoto].Base64
          }
        } 
        //2. Si es un Item invoco recursivamente a esta misma funcion 6
        if(elemento.TipoPregunta == "6"){
          elemento.Detalle.forEach( (subformulario) => {     // tengo un arreglo por eso lo recorreo
            for(const indexSubformulario in subformulario) {  // aqui tengo un objeto con varios objetos enumeroados del 0 en adelante.. Por eso recorreo de esta forma
              if( ( subformulario[indexSubformulario].TipoPregunta == "3") || ( subformulario[indexSubformulario] == "12") ){  // Elmino lo que requiero 
                for(let indexFotosub in subformulario[indexSubformulario].Detalle){
                  this.aFotos.push({...subformulario[indexSubformulario].Detalle[indexFotosub]})
                  delete subformulario[indexSubformulario].Detalle[indexFotosub].Base64   // Borro directo  
                }
              }
            }
          })
        }

        aData.push(elemento)
      })

      return aData
    },
    async validarObligatorios(){  // esta funsion no la puedo volver una utiliadad porque debo alterar variables de este componente.
      // valida los campos obligatorios se regresa 
      // el que sabe quienes son obligatorios es el esquema el cual se recorre y se compara con la respuesta
      this.aErrores = []   // limpio par iniciar
      this.schema.forEach( (campo, index) => {
        if(campo.Obligatorio == "1"){
          const infoCampo =  DinamicosUtils.getInfo_aData(this.aFormData, campo.idFORM_DINAMICOS_Preguntas)
          // de acuerdo al tipo valido si esta diligenciado por valor o textorespuesta o Detalle
          if((infoCampo == undefined) &&  (campo.idFORM_DINAMICOS_Tipos_campos != '13' && campo.idFORM_DINAMICOS_Tipos_campos != '15')){
            // El campo no esta ni diligenciado se marca como error de una 
            this.schema[index].error = true
            this.aErrores.push(campo.Pregunta)
            return
          } else {
            this.schema[index].error = false
          }
          switch (campo.idFORM_DINAMICOS_Tipos_campos) {
            case '3':  // Foto
            case '4':  // Multiselect
            case '6': //Subformulario
            case '12': // Firma
            case '14': // Correo a:
              // por Detalle es un array por lo cual length > 0
              if(infoCampo.Detalle.length == 0){ 
                this.schema[index].error = true
                this.aErrores.push(campo.Pregunta)
                return
              }

            break;
            case '5':  // Checkboox 
              // por valor ValorRespuesta  pero no hago nada por que si es obligatorio se llena por defaul en 0
               this.schema[index].error = false
            break;
            case '13':  // Informacion
            case '15':  // Imagen
               this.schema[index].error = false
            break;
            default:
              // por TextoRespusta
              if(infoCampo.TextoRespuesta == '' || infoCampo.TextoRespuesta == null){
                this.schema[index].error = true
                this.aErrores.push(campo.Pregunta)
                return
              }
            break;
          }
        }
      })

      if(this.aErrores.length >0 ){
        this.mostrarErrores = true
        return false
      } else {
        return true
      }

    },
    closeActivity() {
      APPutils.cancel_Form()
      this.salir()
    }
  },
  computed: {

    ...mapGetters(['getimei', 'getisAPP', 'getIdFormulario'])
  },

}
</script>

<style lang="scss" scoped>
.no-click{
  pointer-events: none!important;
}

</style>