<template>
<v-container fluid class="cont-pagina">
  <v-row class="no-gutters">
    <v-col cols="12">
      <h3 class="titulo-contendor">Información ticket</h3>
    </v-col>
  </v-row>
  <v-row class="no-gutters">
    <v-col cols="12">
      <div class="pa-2 back-gray border-radius__a mt-2">
        <span class="text-label-primary">Sede *</span>
        <v-select
          class="mt-1"
          placeholder="Seleccione la sede"
          v-model="oValor['idSede']"
          :items="aSedes"
          item-text="texto"
          item-value="id"
          @change="guardarIDSede"
          :alt="'select-pqrfinal-sede'"
          dense
          outlined>
        </v-select>
        <v-spacer></v-spacer>
        <span class="text-label-primary">Tipo de requerimiento *</span>
        <v-select
          class="mt-1"
          placeholder="Seleccione tipo"
          v-model="oValor['idRequerimiento']"
          :alt="'select-pqrfinal-tipo'"
          :items="aRequerimientos"
          item-text="texto"
          item-value="id"
          @change="guardarIDSede"
          dense
          outlined>
        </v-select>
        <v-spacer></v-spacer>
        <span class="text-label-primary">Tipo de prioridad *</span>
        <v-select
          class="mt-1"
          placeholder="Seleccione tipo prioridad"
          v-model="oValor['idPrioridad']"
          :alt="'select-pqrfinal-prioridad'"
          :items="aPrioridad"
          item-text="texto"
          item-value="id"
          @change="guardarIDSede"
          dense
          outlined>
        </v-select>
        <v-spacer></v-spacer>
        <span class="text-label-primary">Descripción *</span>
        <v-textarea
          class="mt-1"
          v-model="oValor['descripcion']"
          :alt="'textarea-pqrfinal-descripcion'"
          auto-grow
          outlined
          dense
          color="btncolor"
          placeholder="Escribe la descripción"
          rows="2"
        ></v-textarea>
        <v-spacer></v-spacer>
        <span class="text-label-primary">Anexos</span>
        <DragUpdateFile
          :key="nKeyVista"
          :NoAnexos="5"
          :MaxTamanoFile="52428800"
          :extenAccept="'image/*, application/pdf'"
          @files="onCargarAnexos"
        />
        <v-col v-if="sUrlPoliticas != '' " cols="12" sm="12" md="12" class="d-flex justify-start align-center text-xs-center back-blue-light">
          <v-row class="no-gutters align-center">
            <div class="d-flex align-center">
              <v-checkbox class="margin-auto"
                  v-model="oValor['isPoliticaAceptada']"
                  :alt="'cb-pqrfinal-politicas'"
                  hide-details
               />
                {{$STR.lblAceptarPolitcasDatos}} 
            </div>
            <a
              class="ml-1"
              :href="sUrlPoliticas"
              :target="'_blank'"
              rel="noreferrer noopener"
            >
            {{sUrlPoliticas}}
            </a>
          </v-row>
        </v-col>
        <v-btn color="btncolor" block dark small @click="onEnviarTicket">Enviar Registro</v-btn>
      </div>
    </v-col>
    <ModalMensaje v-model="oModalMensaje.bModalVisible" :sMensaje="oModalMensaje.sMensaje"
     :sTitulo="oModalMensaje.sTitulo"
      :nTipo="oModalMensaje.nTipo" @respuesta="onCerrarModal"/>
  </v-row>

</v-container>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import mxFuncionesBasicas from "@/mixins/FuncionesBasicas.js";
import { mapGetters } from 'vuex';
import HoraFecha from "@/utils/Hora_Fecha.js"


import DragUpdateFile from '@/components/DragUpdateFiles.vue'
export default {
  name: 'NuevoTicket',
  components:{
    DragUpdateFile
  },
  mixins: [mxFuncionesBasicas],
  data() {
    return this.onIniciarVariable()
  },
  mounted() {
    this.sFechaArchivo = HoraFecha.getNowFile()
    this.$store.dispatch('saveLinkStatus', true);
    this.$store.dispatch('saveLinkHeader', 'pqriniciotickte');
    this.onCargarInfo()
  },
  methods: {
    onIniciarVariable () {
      return {
        oValor: {},
        sUrlPoliticas: "",
        oModalMensaje: {
          bModalVisible: false,
          sTitulo: '',
          sMensaje: 'Menseje de prueba',
          nTipo: 2
        },
        aSedes:[],
        aRequerimientos: [],
        aPrioridad: [],
        sFechaArchivo: HoraFecha.getNowFile(),
        nKeyVista: 1
      }
    },
    async onCargarInfo(){
      const oResponse = await axiosServices.getConfiguration('/select/sede/Nombre', false, 'idCliente=' + this.$store.state.oPqrInfo.idCliente)
      if(oResponse.nStatusCode === 200) {
        this.aSedes = oResponse.aData
      }

      const oResponse2 = await axiosServices.getConfiguration('/select/PQR_Tipos_requerimiento/Detalle', false, '')
      if(oResponse2.nStatusCode === 200) {
        this.aRequerimientos = oResponse2.aData
      }

      const oResponse3 = await axiosServices.getConfiguration('/select/PQR_Prioridad_requerimiento/Prioridad', false, '')
      if(oResponse3.nStatusCode === 200) {
        this.aPrioridad = oResponse3.aData
      }

      const oResponseParametro = await axiosServices.getConfiguration('/generico/parametrogeneral/UrlPoliticasPQRFinal', true, '', 'ACF9413C0F77A6268700C8D5DD74E9286DA7667C51A43FADB6A62673B4ABED95')
      if(oResponseParametro.nStatusCode === 200 && oResponseParametro.aData !== '') {
        if(oResponseParametro.aData.Observacion !== ''){
          let tempUrl =  oResponseParametro.aData.Observacion
          this.sUrlPoliticas = (tempUrl.includes('http')) ? tempUrl : 'https://'+tempUrl
        }
      }
   },
    guardarIDSede(){
      return false
    },
    onCargarAnexos(aFiles){
      this.oValor['aArchivos'] = aFiles
    },
    onCerrarModal(val){
      val
      this.nKeyVista ++
    },
    async onEnviarTicket() {
      const aCampos = [
        {
          nombre: 'idSede',
          obl: 1,
          label: 'Sede *'
        },
        {
          nombre: 'idRequerimiento',
          obl: 1,
          label: 'Tipo de requerimiento *'
        },
        {
          nombre: 'idPrioridad',
          obl: 1,
          label: 'Tipo de prioridad *'
        },
        {
          nombre: 'descripcion',
          obl: 1,
          label: 'Descripción'
        }
      ]

      if(this.sUrlPoliticas != ''){
        aCampos.push(        {
          nombre: 'isPoliticaAceptada',
          obl: 1,
          label: 'Politica de privacidad de datos.'
        })
      }

      if(!this.getValidarCampos(aCampos, this.oValor)) {
        const oDataEnvio = {
          app: 'NuevaTienda',
          NombreRemitente: this.getoPqrInfo.sNombre,
          CorreoRemitente:   this.getoPqrInfo.sCorreo,
          Proyecto: this.getoPqrInfo.idCliente,
          Prioridad: this.oValor.idPrioridad,
          TipoRequerimiento: this.oValor.idRequerimiento,
          Descripcion: this.onFormatearTexto(this.oValor.descripcion),
          Anexos: (this.oValor.aArchivos === undefined)? [] : this.onFormatearAnexos(this.oValor.aArchivos,this.sFechaArchivo),
          correos: [],
          Sede: this.oValor.idSede,
          Fecha: HoraFecha.getNow(),
          bPoliticaAceptada: this.oValor['isPoliticaAceptada'],
          Contacto: [
            {
              Nombre: this.getoPqrInfo.sNombre,
              Correo: this.getoPqrInfo.sCorreo,
              Telefono: '0'
            }
          ],
        }
        if (this.oValor.aArchivos !== undefined) this.onSubirAnexos(this.oValor.aArchivos,this.sFechaArchivo)

        const oRegistro = await axiosServices.postConfiguration('/pqr', oDataEnvio, false)
        try{
          if(oRegistro.nStatusCode==200 || oRegistro.nStatusCode==220){
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Registro Exitoso' 
            this.oModalMensaje.sMensaje = oRegistro.sMessage
            this.sFechaArchivo = HoraFecha.getNowFile()
            this.oValor = {}
            this.nKeyVista ++
          }else{
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = oRegistro.sMessage
          }
        }catch(e) {
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
            this.oModalMensaje.sMensaje = 'Reintente nuevamente o verifique su conexión a internet'
        }
        
      }
    },
  },
  computed: {
    ...mapGetters(['getoPqrInfo'])
  },
}
</script>