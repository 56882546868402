<template>
<ContenedorCampos :titulo="''" :isObligatorio="'false'" :error="error"> 
  <v-checkbox
    v-model="selectValue"
    :label="titulo"
  ></v-checkbox>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'CheckBox',
  mixins: [ DinamicosMixin ], 
  components: {
    ContenedorCampos,
  },  
  data () {
    return {
    }
  },
  mounted () {
    // si es obligatorio lo lleno por defecto en false
    if(this.Obligatorio == '1'){
      this.selectValue = 0
    }
  },
  methods: {

  },
  computed: {

  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
