
export default {
    data () {
      return {
        aReglasCampoString: [
            v => !!v || 'Campo es Obligatorio',
            v => v?.length >= 2 || 'Elcampo debe tener al menos dos caracteres',
          ],
      }
    },
  }