
// import ValidarBD from '@/utils/ValidarBD.js'

export default {
  props: [
    'value',          // Donde se Recibe el valor a guardar o guardado respuesta
    'aData',          // Informacion a mostrar
    'titulo',         // Pregunta a realizar == Titutlo
    'id',             // HAce referencia al ID del la pregunta dentro de la tabla de fomulario o detalle_form segun corresponda la estructura  - el el autoincremntal   
    'idPregunta',     // id de la pregunta que se esta mostrando como titulo
    'idTipoCampo',    // Indica el tipo de campo es un numero
    'idGrupo',        // Indica el id del grupo de a pregunta
    'Obligatorio',    // Incia si el elemento es obligatorio o no
    'ModificaA',      // Indica a que campo modifica este valor - se usa para los select fundamentalmente.
    'error',          // Se emplea para aindica que existe un error y pone en rojo el titulo del campo, en especial cuando es obligatorio y no se llena
  ], 
  data () {
    return {
      selectValue: this.value || null,
      aOptions: [],
      // aOptions: ValidarBD.getInfoTabla(this.aData),
    }
  },
  computed: {
    Texto() {
      let texto = null

      switch (this.idTipoCampo) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '11':
          texto = this.selectValue != undefined ? this.aOptions.find( elemento => elemento.ID == this.selectValue).DatoMostrar : ''
        break;
        default:
          texto = this.selectValue
        break;
      }
      return texto
    },
    Valor() {
      let valor = null

      switch (this.idTipoCampo) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '11':
          valor = this.selectValue != undefined ? this.selectValue : ''
        break;
        default:
          valor = ""
        break;
      }
      return valor
    },
    
  },
  watch: {
    // todos los componentes cambia la Variable selectValue con el valor que se les llenna por esto la monitoreo y ante su cambio se genera la estructura para la respuesta
    selectValue (newSelectValue) {
      let aDetall = []      // Variable para armar array de respuestas detalle

      switch (this.idTipoCampo) {
        case '2':
          if (newSelectValue !== null || newSelectValue !== undefined) {
            this.$emit('input',
              {
                IdGrupo : this.idGrupo,
                IdPregunta : this.idPregunta,
                TextoRespuesta : this.Texto,
                ValorRespuesta : this.Valor,
                TipoPregunta : this.idTipoCampo,
                Titulo : this.titulo,
              }
            ) 
          }
          this.$store.dispatch('dinamicos/pushaModificaA',{idPregunta : this.ModificaA , valor: this.Valor})
        break; 
        case '3':
        case '6':  
        case '12':
        case '14':
          this.$emit('input',
              {
                IdGrupo : this.idGrupo,
                IdPregunta : this.idPregunta,
                TextoRespuesta : '',
                ValorRespuesta : '',
                TipoPregunta : this.idTipoCampo,
                Titulo : this.titulo,
                Detalle : newSelectValue
              }
          ) 

        break;
        case '4':
          // como es un multi select y me llega un arreglo de los indices lo recooreo y construllo un nuevo arreglo
          newSelectValue.forEach( e =>
            aDetall.push({
                IdRespuesta : e,
                Respuesta : this.aOptions.find( elemento => elemento.ID == e).DatoMostrar
            })
          )
          this.$emit('input',
            {
              IdGrupo : this.idGrupo,
              IdPregunta : this.idPregunta,
              TextoRespuesta : '',
              ValorRespuesta : '',
              TipoPregunta : this.idTipoCampo,
              Titulo : this.titulo,
              Detalle : aDetall
            }
          ) 
        break;  
        case '5':
            this.$emit('input',
            {
              IdGrupo : this.idGrupo,
              IdPregunta : this.idPregunta,
              TextoRespuesta : '',
              ValorRespuesta : newSelectValue ? "1" : "0",
              TipoPregunta : this.idTipoCampo,
              Titulo : this.titulo,
            }
          ) 
        break; 
        default:
          if (newSelectValue !== null || newSelectValue !== undefined) {
            this.$emit('input',
              {
                IdGrupo : this.idGrupo,
                IdPregunta : this.idPregunta,
                TextoRespuesta : this.Texto,
                ValorRespuesta : this.Valor,
                TipoPregunta : this.idTipoCampo,
                Titulo : this.titulo,
              }
            ) 
          }
        break;
      }

    }
  }

}