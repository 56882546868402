<template>
  <v-row>
    <v-bottom-sheet
      v-model="value"
      persistent
      scrollable
    >
    <v-card
      class="rounded-tl-xxl rounded-tr-xxl"
    >
        
    <v-card-text style="height: 80vh;  overflow: auto; padding: 0;" >
        <v-container class="pa-0 ma-0" fluid>
          
          <div class="px-1 pt-3 mt-4">
            <div class="d-flex justify-space-between">
              <span class="px-1 pt-2 black--text text-size-2 text-semibold">{{$STR.ttlEditarElemento}}</span>
              <v-btn
                text
                dark
                @click="onCancelar"
              >
              <span class="pt-2 color-primary text-size1">{{$STR.btnCerrar}}</span>
              </v-btn>
            </div>
          </div>

          <div class="px-1">
            <v-col
              cols="12" md="12" xl="12" sm="12" lg="12"
            >
            <v-row class="px-2"><span class="ntitlesdark--text">QR</span></v-row>
           
            <v-row class="px-2">
              <div class="d-flex align-center">
                <vue-qr :text="oElemento.QR" :qid="oElemento.QR" height="90px"></vue-qr>
                <v-btn
                  outlined
                  color="primary"
                  light
                  small
                  class="btn-primary btn-rounded mx-auto"
                  @click="onClickCambiarQR()"
                >
                  {{ $STR.btnCambiarQR }} <v-icon right>mdi-qrcode-edit</v-icon>
                </v-btn>
              </div>
            </v-row>
             
            </v-col>
            
            <v-col
              cols="12" md="12" xl="12" sm="12" lg="12"
            > 
            <v-divider class="pb-2"></v-divider>
              <span class="ntitlesdark--text">{{$STR.cmpObs}}</span>
              <v-textarea
                v-model="oElemento.Detalle"
                :placeholder="$STR.lblAgregarObservacion"
                rows="3"
                row-height="5"
                required
                outlined
                
              ></v-textarea>
            </v-col>
          </div>

          
          <div class="px-1">
            <span class="px-2 mt-5 black--text text-size-2 text-semibold">{{$STR.ttlResumenElemento}}</span>
            <v-col
              cols="12" md="12" xl="12" sm="12" lg="12"
            >
              <span class="ntitlesdark--text">{{$STR.stlTipoElemento}}</span>
              <v-text-field
                v-model="oElemento.TipoElemento"
                outlined
                dense
                clearable
                disabled
              ></v-text-field>
              <span class="ntitlesdark--text">{{$STR.stlMarca}}</span>
              <v-text-field
                v-model="oElemento.Marca"
                outlined
                dense
                clearable
                disabled
              ></v-text-field>
              <span class="ntitlesdark--text">{{$STR.stlColor}}</span>
              <v-text-field
                v-model="oElemento.Color"
                outlined
                dense
                clearable
                disabled
              ></v-text-field>
              <div class="foto-elemento mt-1">
                <v-img
                  class="img-container-rounded"
                  v-if="oElemento.Fotos.length >0"
                  :src="oElemento.Fotos[0].NombreAnexo"
                  width="90"
                  height="90"
                  cover
                ></v-img>
                <v-img
                  class="img-container-rounded"
                  v-else
                  :src="require('@/assets/logo.png')"
                  width="100"
                  height="100"
                  cover
                ></v-img>
            
              </div>
            </v-col>
          </div>
        
        </v-container>     
      </v-card-text>

      <v-card-actions>
        <v-row class="px-0">
          <v-col
            class="py-1"
            cols="12" md="12" xl="12" sm="12" lg="12"
          >
            <v-btn
              outlined
              class="btn-primary btn-rounded "
              color="primary"
              dark
              block
              @click="onCancelar"
            >
              <span class="black--text">{{$STR.btnCancelar}}</span>
            </v-btn>
          </v-col>

          <v-col
            class="pt-1"
            cols="12" md="12" xl="12" sm="12" lg="12"
          >
            <v-btn
              class="btn-primary btn-rounded"
              color="primary"
              dark
              block
              @click="onGuardar()"
            >
              <span>{{$STR.btnGuardar}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      
    </v-card>
    <loader v-model="isCargando"/>
  </v-bottom-sheet>
  <LecturaQR 
    v-if="isLecturaQR" 
    :activo="isLecturaQR"
    :onLecturaCompletada="onCargarInfoQR"
    @cerrarQR="cerrarlectorqr"
  />
  <v-snackbar
      v-model="bMensaje"
    >
    <p v-html="sMensaje"></p>
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        @click="bMensaje = false"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
  </v-row>
</template>

<script>
import ReglasMixin from '@/mixins/ReglasMixin.js'
import LecturaQR from '@/components/LecturaQR.vue' 
import Fotos from '@/components/Fotos.vue'
import ValidarBD from '@/utils/ValidarBD.js'
import axios from '@/services/axiosServices.js'
import Loader from '@/components/Loader.vue'
import VueQr from 'vue-qr';

export default {
  name: 'EditarElemento',
  mixins: [ ReglasMixin ], 
  components: { 
    LecturaQR,
    Fotos,
    Loader,
    VueQr
   },
	props: {
      value: {
        type: Boolean,
        default: false
      },
      bMostrar: {
        type: Boolean,
        default: false
      },
      oElemento: {
        type: Object,
        defalt: {}
      },
      onValidarQRElementosInsertados: {
        type: Function
      }
    },
  data () {
		return {
      isCargando: false,
      isLecturaQR: false,
      isNuevoElemento: false,
      aTipoElementos: [],
      oKeyFoto: 1,
      sMensaje: '',
      bMensaje: false,
    }
	},
	mounted() {
    this.aTipoElementos = ValidarBD.getInfoTabla('ELEMENTOS_TipoElemento')
	},
  methods: {
    onGuardar() {
      if(!this.isFormularioValido()){
        this.oElemento.isNuevo = true
        this.$emit('onGuardar', this.oElemento)
      }else{
        this.bMensaje = true
        //this.sMensaje = ''
        return
      }
    },
    isFormularioValido(){
      this.sMensaje = ''
      let error = false
      if(this.oElemento.Detalle==''){
        this.sMensaje += '- Detalle no diligenciado.<br>'
        error = true
      }
      /*if(this.idTipoVisitante!="1" && this.oElemento.QR==''){
        this.sMensaje += '- QR no escaneado.<br>'
        error = true
      }*/

      return error
    },
    onClickCambiarQR () {
      this.isLecturaQR = true
      this.sTipoLectura = 'CambiarQR'
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase.analytics().logEvent('registroElementoQR', { CambiarQR:1})
    },
    onCargarInfoQR(sCodigo){
      this.isLecturaQR = false
      this.isCargando = true
      axios.getConfiguration(`elementos-qr/qr/${sCodigo}`, true, '').then( response =>{
        this.isCargando = false
        let mensaje = ''
        if (response.sStatus == 'success'){
          mensaje = response.sMessage
          if(response.aData == '1'){
            if(!this.onValidarQRElementosInsertados(sCodigo)){
              this.oElemento.QR = sCodigo
            }else{
              mensaje = "El Qr ya lo relacionó a otro elemento"
            }
          }
        }else{
          mensaje = this.$STR.msgErrorEnvio
        }
        this.$store.dispatch('notificaciones/SetMensaje', mensaje)
        this.$store.dispatch('notificaciones/ShowNotificacion',true) 
      })
      
      
      //console.log("Lectura", sCodigo)
    },
    cerrarlectorqr(){
      this.isLecturaQR = false
    },
    onCancelar(){
      this.$emit("onCancelar", "")
    }
  },
	watch: {
		value(NewValor){
			this.isNuevoElemento = NewValor
		},
    isNuevoElemento(NewValor){
			this.$emit('input',NewValor)
		},
	},

}
</script>

<style lang="scss" >
.foto-elemento{
  height: 90px;
  max-width: 90px;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}
</style>