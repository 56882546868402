<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-text-field
    v-model="selectValue"
    outlined
    dense
    clearable
  ></v-text-field>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'EditTextAlfanumerico',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },

}
</script>

<style lang="scss" scoped>
</style>
