<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error" class="under-sec">
  <div v-for="(firma, index) in aFirmas" :key="index">
    <v-row>
      <v-col cols="6">
        <p>{{firma.Asistente}}</p>
      </v-col>
      <v-col cols="4">
        <img :src="firma.Base64" width="50%" @click="ampliar(firma.firma, firma.nombre)">
      </v-col>
      <v-col cols="2">
        <v-icon small color="red darken-2" @click="borrar(index)">mdi-delete</v-icon>
      </v-col>    
    </v-row>
  </div>
  <Signature @firmado="verFirma"></Signature>
  <AmpliarFoto 
    :fotoampliada="ftampliada" 
    :textoampliado="txtampliado" 
    v-model="showFoto"
  ></AmpliarFoto>

</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import Signature from '@/components/dinamicos/compAuxiliares/signature.vue'
import AmpliarFoto from '@/components/dinamicos/compAuxiliares/AmpliarFoto.vue'
import HoraFecha from '@/utils/Hora_Fecha.js'
import { mapGetters } from 'vuex'
import APPutils from '@/utils/APPutils.js'

export default {
  name: 'Firma',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
    Signature,
    AmpliarFoto
  },
  data () {
    return {
      aFirmas : [],
      showFoto: false,
      ftampliada : null,
      txtampliado : null
    }
  },
  mounted () {
  },
  methods: {
    verFirma(oInfo){
      const auxFirma = {Base64: oInfo.data, Asistente: oInfo.nombre , Firma : `Signature_${HoraFecha.getNowFile()}.jpg` }
      this.aFirmas.push(auxFirma)
      if(this.getisAPP){
        APPutils.enviarFirma(JSON.stringify(auxFirma))
      }
    },
    borrar(index){
      this.aFirmas.splice(index, 1)

    },
    ampliar(val, text){
      this.showFoto = true
      this.ftampliada = val
      this.txtampliado = text
    },
  },
  computed: {
    ...mapGetters(['getisAPP'])
  },
  watch: {
    aFirmas(newFirmas){
      this.selectValue = newFirmas

    }
  }

}
</script>

<style lang="scss" scoped>

</style>
