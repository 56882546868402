<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-btn-toggle
    v-model="selectValue"
    color="info"
    background-color="backgrey">
    <v-btn 
      width="100" 
      v-for="(info, key) in aOptions" 
      :key="key"  
      :value="info.ID">
      {{info.DatoMostrar}}
    </v-btn>
  </v-btn-toggle>
</ContenedorCampos>
</template>

<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import ValidarBD from '@/utils/ValidarBD.js'

export default {
  name: 'ToggleButton',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {
    }
  },
  mounted () {
    // Debo obtener los valores a mostra los cuales se guardan en la tabla local storage que vienen en Valor bajo la estructura
    // DatoMostrar: lo que se muestar -    Filtro: para filtrar   -   ID: mi ID   -   Indice: NO usado

    this.aOptions =  ValidarBD.getInfoTabla(this.aData)
  },
  computed: {
  },

}
</script>

<style lang="scss" scoped>

</style>
