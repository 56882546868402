<template>
  <div class="container-dinamicos" >
    <component v-for="(campo, index) in schema" :key="index"
      :is="campo.TipoCampo"
      v-model="oFormCampos[index]"
      :id="campo.idFORM_DINAMICOS_Formulario"
      :titulo="campo.Pregunta"
      :idGrupo="campo.idFORM_DINAMICOS_GRUPOS"
      :idPregunta ="campo.idFORM_DINAMICOS_Preguntas"
      :aData="campo.Valor"
      :idTipoCampo="campo.idFORM_DINAMICOS_Tipos_campos"
      :Obligatorio="campo.Obligatorio"
      :ModificaA="campo.Modifica_A_ID"
      :error="campo.error"
      >
    </component>
  </div>
</template>

<script>
// Modelo para condicionar estilo según tipo valor
// :class="{'para-fotos' : (campo.TipoCampo == 'Foto' || campo.TipoCampo == 'Imagen' )}"

/* Este componente se conviere en global para poderse llamar en cualquier lado y dentro de si mismo.*/

import Grupo from '@/components/dinamicos/campos/Grupo' 
import ToggleButton from '@/components/dinamicos/campos/ToggleButton' 
import MultiSelect from '@/components/dinamicos/campos/MultiSelect'
import Select from '@/components/dinamicos/campos/Select'
import RadioButton from '@/components/dinamicos/campos/RadioButton'
import EditTextAlfanumerico from '@/components/dinamicos/campos/EditTextAlfanumerico'
import EditTextNumeros from '@/components/dinamicos/campos/EditTextNumeros'
import EditTextTexto from '@/components/dinamicos/campos/EditTextTexto'
import Imagen from '@/components/dinamicos/campos/Imagen'
import CheckBox from '@/components/dinamicos/campos/CheckBox'
import Foto from '@/components/dinamicos/campos/Foto'
import Calendarios from '@/components/dinamicos/campos/Calendarios'
import Firma from '@/components/dinamicos/campos/Firma'
import Informacion from '@/components/dinamicos/campos/Informacion'
import Item from '@/components/dinamicos/campos/Item'
import Correo from '@/components/dinamicos/campos/Correo'

export default {
  name: 'GeneradorCampos',
  components: {
    Grupo, 
    ToggleButton,
    MultiSelect,
    Select,
    RadioButton,
    EditTextAlfanumerico,
    EditTextNumeros,
    EditTextTexto,
    Imagen,
    CheckBox,
    Foto,
    Calendarios,
    Firma,
    Informacion,
    Item,
    Correo,

  },
  props: ['schema', 'value'],
  data () {
    return {
      oFormCampos: this.value || {}
    }
  },
  methods: {
    // updateForm (fieldName, value) {
    //   this.$emit('input', this.formData)
    // }
  }
}
</script>

<style lang="scss" scoped>
.container-dinamicos{
  margin: 0;
  padding: 0;
  padding-top: 10px;
}
</style>
