<template>
  <v-form ref="formPQR" v-model="bFormularioValido" lazy-validation class="pa-2 back-gray border-radius__a"  >
    <span class="text-label-primary">Ingresa tu nombre *</span>
    <v-text-field
      class="mt-1"
      v-model="oValor['sNombre']"
      :alt="'text-pqrfinal-nombre-persona'"
      single-line
      outlined
      clearable
      dense
      placeholder="Escribe tu nombre"
      :rules="[rules.required]"
    />
    <span class="text-label-primary">Ingresa tu correo electrónico *</span>
    <v-btn icon @click="$emit('ayudaCorreo', true)" x-small><v-icon>mdi-help-circle-outline</v-icon></v-btn>
    <v-text-field
      class="mt-1"
      v-model="oValor['sCorreo']"
      :alt="'text-pqrfinal-correo-persona'"
      single-line
      outlined
      clearable
      dense
      placeholder="Escribe tu correo electronico"
      prepend-icon="mdi-email"
      :rules="[rules.required, rules.email, rules.emailCantidad]"
    />
    <v-btn color="btncolor" class="mt-2" block dark small @click="onContinuar">Continuar</v-btn>
  </v-form>
</template>
<script>
import mxFuncionesBasicas from "@/mixins/FuncionesBasicas.js";

export default {
  name:'PqrFormNombre',
  props: {
      sIdCliente: {
        type: String,
        dafault: ''
      }
    },
  mixins: [mxFuncionesBasicas],
  data() {
    return {
      bFormularioValido: false,
      oValor:[],
      resultadoValidar: null,
      rules: {
        required: value => !!value || 'Requerido.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          let bError = false
          if(value !== undefined){
            value.split(';').forEach(sItem => {
              bError =  pattern.test(sItem) 
            })
          }
          return bError || 'Correo Inválido. Verifique si digita mas de un correo que este separado por ;'
        },
        emailCantidad: value => (value !== undefined) ? value.split(';').length<=3 || 'Máximo se permiten 3 correos' : false
      },
      aCampos: [
        {
          nombre: 'sCorreo',
          obl: 1,
          label: 'Ingresa tu correo electrónico'
        },
        {
          nombre: 'sNombre',
          obl: 1,
          label: 'Ingresa tu nombre'
        }
      ]
    }
  },
  methods: {
   async onContinuar(){
      await this.validador()
      if(!this.resultadoValidar) {
        if(this.bFormularioValido) {
          this.$firebase.analytics().logEvent('PQRTicket', { CorreoValido:1})
          this.$emit('ValidarNombre')
          this.oValor.idCliente = this.sIdCliente
          this.$store.dispatch('setoPqrInfo',this.oValor )
        }else{
          this.$firebase.analytics().logEvent('PQRTicket', { CorreoInValido:1})
        }
      }
    },
    validador(){
      this.resultadoValidar = this.getValidarCampos(this.aCampos, this.oValor)
    }
  },
  computed: {
    sCargarCorreo() {
      return (Object.prototype.hasOwnProperty.call(this.oValor, 'sCorreo') ? this.oValor.sCorreo.replace(' ','') : '')
    },
  },
  watch: {
    sCargarCorreo () {
      this.oValor.sCorreo = this.sCargarCorreo
    }
  }
}
</script>