<template>
  <v-btn  fab outlined small color="primary" elevation="0" :disabled="isActivo">
    <v-icon  color="primary" @click="$emit('descargar')" dense size="20px">
      {{getIcono.icono}}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name:"IconEstado",
  props: {
    estado: {
      type: Number,
      default: 0
    },
    isActivo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      aIcons : [
        {estado: 0, icono: "fas fa-exclamation-triangle"},  // PENDIENTE
        {estado: 1, icono: "fas fa-cloud-download-alt"},    // DESCARGANDO ..
        {estado: 2, icono: "fas fa-check-circle"},          // DESCARGADO
        {estado: 3, icono: "fas fa-times-circle"},          // ERROR
      ]
    }
  },
  computed: {
    getIcono() {
      return this.aIcons.find( elemento => elemento.estado == this.estado)
    }
  },

}
</script>

<style>

</style>