import Vue from "vue";
import App from "./App.vue";
import './registerServiceWorker'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from 'axios'
import VueAxios from 'vue-axios'
import './firebase/firebase'
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css/animate.min.css'
import GeneradorCampos from '@/components/dinamicos/GeneradorCampos.vue'  // Como lo uso en dos lados lo vuelvo global
import str from './assets/strings.js'

// NProgress - https://www.npmjs.com/package/vue-nprogress
import 'nprogress/nprogress.css'

// Manejo de captura de firma https://www.npmjs.com/package/vue-signature-pad
import VueSignaturePad from 'vue-signature-pad'

// sweetalert 2 
import VueSweetalert2 from 'vue-sweetalert2';  
import 'sweetalert2/dist/sweetalert2.min.css'
import axiosServices from "@/services/axiosServices.js"

Vue.use(VueAxios, axios)
Vue.use(VueSignaturePad)

Vue.component('GeneradorCampos', GeneradorCampos)

Vue.config.productionTip = false;
Vue.prototype.$STR = str
Vue.prototype.$axiosServices = axiosServices

import ModalMensaje from '@/components/ModalMensaje.vue'
Vue.component("ModalMensaje", ModalMensaje)
// sweetalert2
Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  // procesos para manejar en online y offline
  created () {
    window.addEventListener('offline', () => {
      store.dispatch('setisOnline', false)
    })
    window.addEventListener('online', () => {
      store.dispatch('setisOnline', true)
    })
  },
}).$mount("#app");
