/*
    ttl = titulo
    stl = subtitulo
    btn = boton
    lbl = label
    cmp = campo
    msg = mensaje
*/

export default ({
    // ModuloElementos
    ttlLecturaQR: 'Leer el QR del elemento',
    btnLecturaQR: 'Registra por QR',
    ttlIdentificacionQR: 'Ingresa el número de identificación de la persona',
    btnRegistrar: 'Registrar',
    lblIdentificacion: 'Ejemplo: 1234567890',
    lblFecha: 'Fecha: ',
    lblHora: 'Hora: ',
    btnSiguiente: 'Siguiente',
    btnFinalizarRegistro: 'Finalizar Registro',
    lblNroIdentificacion: 'ID',
    lblNombreVisitante: 'Nombre de Persona',
    btnTomarFoto: 'Tomar Foto',
    ttlValidaInfo: 'Valida la información registrada',
    ttlregistroSalidaEntrada: 'Realiza el registro de la Salida/Entrada',
    stlInfoVisitante: 'Información de persona',
    stlInfoVehiculos: 'Información de elementos asociados',
    stlInfoNuevoElemento: 'Presiona el botón para añadir elemento',
    stlTipoElemento: 'Tipo Elemento',
    stlMarca: 'Marca',
    stlColor: 'Color',
    btnVerQR: 'Ver QR',
    btnCambiarQR: 'Cambiar QR',
    ttlNuevoElemento: 'Nuevo Elemento',
    btnAñadir: 'Añadir',
    cmpTipoElementoObligatorio: 'Tipo Elemento*',
    cmpMarcaObligatorio: 'Marca*',
    cmpColorObligatorio: 'Color*',
    cmpQRObligatorio: 'Seleccione el icono para capturar la lectura QR*',
    lblMarca: 'Ejemplo: Shimano',
    lblColor: 'Ejemplo: Rojo',
    stlInfoRegistro: 'Información de registro',
    cmpProcesoObligatorio: 'Selecciona el proceso a realizar*',
    cmpObsObligatorio: 'Observaciones *',
    cmpObs: 'Observaciones',
    lblQrLeido: 'El Qr ha sido procesado',
    lblObs: 'Ejemplo: Escriba cualquier observaciones general de la entrada o salida',
    lblAnexos: 'Anexos',
    ttlCodigoQR: 'Código QR',
    msgMaxFotos: 'Notificación: Unicamente puede tomar máximo XXX Fotos',
    ttlTomaFotos: 'Toma de Fotografias',
    msgQRLeido: 'EL QR ya ha sido agregado',
    msgQRNoExistente: 'El QR no existe o no esta relacionado a ninguna persona',
    msgQRNoPersona: 'El QR no pertenece a la persona, registra a nombre de XXX identificado con YYY que esta haciendo el registro',
    msgNroIdentificacionNovalido: 'El número de identificación no válido',
    NuevoVisitante: 'Nueva persona',
    msgVisitanteNoExistente: 'Persona no registrada en el sistema',
    cmpCedulaObligatorio: 'No identificación*',
    lblCedula: 'Ejemplo: 1234567890',
    cmpNombreVisitanteObligatorio: 'Nombre de la persona*',
    lblNombreVisitante2: 'Escribe el nombre',
    ttlNuevoVisitante: 'Nueva Persona',
    cmpDetalle: 'Detalle*',
    lblDetalle: 'Escriba alguna descripción del elemento',
    msgFormularioElemento: 'Recuerde diligenciar toda la información obligatoria',
    msgFaltanElementosVisitantes: 'Faltan elementos o persona por validar',
    lblTipoVisitante:'Tipo de Persona',
    cmpTipoVisitanteObligatorio: 'Tipo de Persona*',
    textoElementosSeleccionar: 'Selecciona un elemento para continuar con el proceso de registro',
    cmpAccesoObligatorio: 'Acceso*',
    msgFaltanDatosregistro: 'Faltan datos de registro por diligenciar',
    ttlFotos: 'Fotos',
    msgErrorEnvio: 'Error de envió  de información. Intente nuevamente',
    ttlEditarElemento: 'Editar Elemento',
    lblEditar: 'Editar',
    ttlResumenElemento: 'Resumen de elemento',
    btnGuardar: 'Guardar',
    btnCancelar: 'Cancelar',
    btnCerrar: 'Cerrar',
    lblAgregarObservacion: 'Añade acá tus observaciones',
    lblAceptarPolitcasDatos: 'He leído y estoy de acuerdo con la politica de privacidad de datos.',
    lblUrlPoliticasDato: "www.politicadatos.com",
    cmpEstado: "Estado",
    lblPersonaDentro: "La persona ya se encuentra dentro de las instalaciones",
    lblIngresoOn: "Ya tiene ingreso",
    lblIngresoOff: "No tiene ingreso"
})