<template>
<v-container fluid class="cont-pqrcliente">
  <v-row class="no-gutters">
    <v-col cols="12">
      <h3 class="titulo-contendor">Bienvenido al sistema de ticket</h3>
    </v-col>
  </v-row>
  <v-row class="no-gutters mt-2">
    <v-col cols="12">
      <div class="pa-2 back-gray border-radius__a">
        <span class="text-label-primary">Ingresa el NIT de tu empresa *</span>
        <v-text-field
          class="mt-1"
          type="number"
          :alt="'text-pqrfinal-nit'"
          v-model="sValor"
          single-line
          outlined
          clearable
          dense
          placeholder="Escribe NIT sin dígito de verificación"
        />
        <v-btn color="btncolor" block dark small @click="onValidarNit">Validar NIT</v-btn>
      </div>
    </v-col>
    <v-col 
      cols="12" 
      class="mt-2 animate__animated animate__fadeInUp"
      v-if="bNitValidado">
      <FormNombre
      :sIdCliente="idCliente"
      @ValidarNombre="aValidarNombre"
      @ayudaCorreo="aAyudaCorreo"
      />
    </v-col>
  </v-row>
  <ModalMensaje 
  v-model="oModalMensaje.bModalVisible" 
  :sTitulo="oModalMensaje.sTitulo"
  :sMensaje="oModalMensaje.sMensaje"
  :nTipo="oModalMensaje.nTipo" 
  @respuesta="aValidarAction"/>
</v-container>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import FormNombre from './PqrFormNombre.vue'

export default {
  components:{
    FormNombre
  },
  data() {
    return {
      sValor:'',
      bNitValidado: false,
      oModalMensaje: {
        bModalVisible: false,
        sTitulo: '',
        sMensaje: '',
        nTipo: 2
      },
      idCliente: ''
    }
  },
  mounted() {
     this.$store.dispatch('savesPerfil', '')
     
  },
  methods: {
    async onValidarNit(){
      this.idCliente = ''
      if(this.sValor===''){
        this.oModalMensaje.bModalVisible = true
        this.oModalMensaje.sMensaje = "El NIT no puede estar vacío"
        return
      }
      const oResponse = await axiosServices.getConfiguration('/pqr/validar/cliente', false, 'nit=' + this.sValor)
      if(oResponse.nStatusCode === 200) {
        if (oResponse.aData.bExiste) {
          if(oResponse.aData.bEstado === '1'){
            this.bNitValidado = true
            this.idCliente = oResponse.aData.idCliente
            this.$firebase.analytics().logEvent('PQRTicket', { NITCorrecto:1})
          }else{
            this.oModalMensaje.bModalVisible = true
            this.oModalMensaje.sMensaje = "El cliente no se encuentra activo"
            this.$firebase.analytics().logEvent('PQRTicket', { NITInactivo:1})
          }
        } else {
          this.oModalMensaje.bModalVisible = true
          this.oModalMensaje.sTitulo = 'El NIT ingresado es incorrecto' 
          this.oModalMensaje.sMensaje = "Válida el número registrado. Recuerda que no debes escribir el digito de verificación"
          this.$firebase.analytics().logEvent('PQRTicket', { NITIncorrecto:1})
        }
      } else {
        this.oModalMensaje.bModalVisible = true
        this.oModalMensaje.sTitulo = 'Error en la conexión de la red' 
        this.oModalMensaje.sMensaje = oResponse.sMessage
        this.$firebase.analytics().logEvent('PQRTicket', { ErrorRed:1})
      }
    },
    aValidarAction(){
      this.oModalMensaje.bModalVisible = false
      // console.log('Action aValidarAction' , val)
    },
    aValidarNombre(){
      this.$router.push('/pqrInicioTicket').catch(() => { this.$router.push({ name: 'pqriniciotickte' })});
      // this.oModalMensaje.bModalVisible = true
    },
    aAyudaCorreo(val){
      this.oModalMensaje.bModalVisible = val
      this.oModalMensaje.sMensaje = "Valida que el correo electrónico ingresado se encuentre activo y cuente con el formato:<br><br>correo@correo.com"
    }
  },
  watch: {
    sValor() {
      this.bNitValidado = false
      if(typeof this.sValor === 'object') {
        this.sValor = ''
      }
    }
  },
}
</script>
<style lang="scss" scoped>
</style>