<template>
  <v-overlay :value="isCargando">
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      :width="7"
    />
  </v-overlay>
</template>

<script>

export default {
  name: 'Loader',
  components: {  },
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isCargando: false,
    };
  },
  mounted() {
    this.isCargando = this.value;
  },
  watch: {
    isCargando(NewValor) {
      this.$emit('input',NewValor)
    },
    value(newValor){
      this.isCargando = newValor
    }
  },
};
</script>
