<template>
  <v-btn-toggle v-model="selectValue" active-class="btn-activo" mandatory background-color="backgrey" class="my-2" >
    <v-btn class="btn-rounded px-3 py-1" width="120" v-for="(info, key) in aData" :key="key" :value="info.ID" :disabled="info.disabled"> 
      {{info.DatoMostrar}}
    </v-btn>
  </v-btn-toggle>
</template>

<script>

export default {
  name: 'ToggleButton',
  components: { },
  props: {
      value: {
        type: String,
        default: ''
      },
			aData: {
        type: Array,
        default: ()=> []
      },
      bEntrada: {
        type: Boolean,
        default: true
      }
    },
  data () {
    return {
      selectValue: ''
    }
  },
  mounted () {
    if(!this.bEntrada) this.selectValue = this.value
  },
  watch: {
    selectValue(newValor){
      this.$emit('input', newValor)
    }
  },

}
</script>

<style lang="scss" scoped>
.btn-activo{
  background: var(--v-primary-base)!important;
  color: white!important;
}
</style>
