<template>
  <div>
    <v-app-bar fixed app height="40px" 
    class="navbar rounded-b-lg"
    elevation="4"
    light
    :class="estado ? 'color-online':'color-offline'"
    >
    <v-btn large icon color="primary"
    v-show="$store.state.sLinkStatus && getbMenuBlock" 
    @click="goTo()">
      <v-icon color="#FFFFFF">mdi-chevron-left</v-icon>
    </v-btn>
    <div d-flex>
      <h4>{{nombreUsuario}}</h4>
    </div>
    <v-spacer></v-spacer>
    <div class="icon-off" v-if="!estado">
      <v-icon dark>mdi-wifi-off</v-icon>
    </div>
    <v-menu offset-y v-if="verMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            color="white"
            v-bind="attrs"
            v-on="on" >
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in itemsMenu" :key="index" >
          <v-list-item-subtitle @click="openSalir">{{ item.label }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
    <LogOut v-if="getLogin" v-model="dialogLogOut" ></LogOut>
  </div>  
</template>

<script>
import LogOut from '@/components/LogOut.vue'
import { mapGetters } from 'vuex'
export default {
  name: "HeaderNav",
  components: {
    LogOut,
  },
  props:{
    verMenu:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      itemsMenu: [
        { label : 'Salir', link : 'LogOut' },
      ],
      dialogLogOut : false,
      estado : true,
      nombreUsuario: ''
    }
  },
  methods: {
    openSalir(){
      this.dialogLogOut = true
    },
    goTo(){
      this.$router.replace({name:this.$store.state.sLinkHeader})
      // console.log(this.$store.state.sLinkHeader)
    },
  },
  computed: {
    ...mapGetters(['getLogin']),
    ...mapGetters(['getbMenuBlock']),
    ...mapGetters(['getisOnline']),
    ...mapGetters(['getsPerfil']),
  },
  watch: {
    getisOnline(newValue) {
      this.estado = newValue
    },
    getsPerfil(newValue) {
      this.nombreUsuario = newValue
    }
  },
}
</script>
<style lang="scss" scoped>
.navbar{
  // border-radius: 0 0 10px 10px!important;
  color: white;
  margin-bottom: 10px;
}
h4{
  font-family: 'Poppins';
  font-weight: 500;
}
.color-online{
  background: var(--v-primary-base)!important;
}
.color-offline{
  background:#ff827b!important;
}
</style>