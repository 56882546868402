<template>
<div>
  <div  v-for="(info, index) in infoMostrar" :key="index">
  <v-card>
    <v-card-title>
      Fecha Registro:
    </v-card-title>
    <v-card-subtitle>
      {{Fecha(info)}}
    </v-card-subtitle>
    <v-card-title>
      Acción :
    </v-card-title>
    <v-card-subtitle>
      {{Accion(info)}}
    </v-card-subtitle>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="enviar(info, index)">
        Enviar
      </v-btn>
    </v-card-actions>

  </v-card>
  </div>
  <v-btn color="primary" class="btn-float mr-15" dark fixed medium fab @click="allsend" :disabled="disableAll">
    <v-icon dense size="20px">fas fa-cloud-download-alt</v-icon>
  </v-btn>
</div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'


import { mapGetters } from 'vuex'
  export default {
    name: "Pendientes",
    data() {
      return {
        disableAll: false
      }
    },
    methods: {
      Fecha(info) {
        return JSON.parse(info.data).Fecha
      },
      Accion(info){
        return JSON.parse(info.data).accion
      },
      enviar(info, index){
        axiosServices.enviarOffline( info, index)

      },
      async allsend(){
        this.disableAll = true
        await  axiosServices.enviarAllOffline()
        this.disableAll = false

      },
    },
    computed: {

      infoMostrar(){
        // solo se muestran los NOEnviar
        let axuInfo = []
        this.getaAxiosOffline.forEach(elemento => {
          if(elemento.method != 'NOEnviar'){
             axuInfo.push(elemento)
          }
        })

        return axuInfo
      },

      ...mapGetters('axiosOffline', ['getaAxiosOffline'])
    },
  }
</script>

<style lang="scss" scoped>

</style>