import store from '@/store/index.js'
import DinamicosUtils from '@/utils/DinamicosUtils.js'


// Toda la información que llega esta en string por eso hay que pasarla a objeto con JSON.parse()

export default {
  // con esta funcion extraigo toda la informacion que requiero para trabajar normalmente
  async getInfo(){
    this.getUsuario()
    this.getGrupos()              // primero siempre grupos
    this.getPreguntas()
    await DinamicosUtils.getPreguntasValores()
  },
  getInfoElementos(){
    this.getUsuario()
    this.getTiposPersona()
    this.getTipoElementos()
    this.getAccesos()
  },
  getAccesos(){
    const aTipoElementos = JSON.parse(IApp.getAccesos())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'Accesos', info: aTipoElementos})
  },
  getTipoElementos(){
    const aTipoElementos = JSON.parse(IApp.getTipoElementos())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'ELEMENTOS_TipoElemento', info: aTipoElementos})
  },
  getTiposPersona(){
    const aTipoPersona = JSON.parse(IApp.getTiposPersona())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'TipoPersona', info: aTipoPersona})
  },
  getUsuario(){
    const aInfoUsuario = JSON.parse(IApp.getUsuario())  // eslint-disable-line
    
    // guardo en store
    store.dispatch('setRegistrarLogin', {
      permisos : [] ,
      iduser : aInfoUsuario.iduser,
      nombreusuario : aInfoUsuario.nombreusuario,
      idperfil : aInfoUsuario.idperfil,
      perfil :aInfoUsuario.perfil,
      idciudad :0,
      actualizaciones : [],
      sedes : [],
      sToken : aInfoUsuario.token
    })

  },
  getPreguntas(){
    
    const aPreguntas = JSON.parse(IApp.getPreguntas())  // eslint-disable-line
    
    // guardo en Database bajo el nombre de tabla : formularios-dinamicos/preguntas
    aPreguntas.forEach( (Pregunta,key) => {                                        
      
      if(Pregunta.idFORM_DINAMICOS_Tipos_campos == "6"){  // el item me llega como un String y se debe convertir a Objeto
        aPreguntas[key].Valor= JSON.parse(Pregunta.Valor)
      } else {
        aPreguntas[key].Valor = Pregunta.Valor.replaceAll('"','')  // Se hace por que el valor llega con " al principio y al final
      }
    })   
    store.dispatch('dataBase/salvarTabla', {tablaName : 'formularios-dinamicos/preguntas', info: aPreguntas})
  },
  getGrupos(){
    const aGrupos = JSON.parse(IApp.getGrupos())  // eslint-disable-line
    // guardo en Database bajo el nombre de tabla : FD_Grupos
    store.dispatch('dataBase/salvarTabla', {tablaName : 'FD_Grupos', info: aGrupos})
  },
  getValores(Valor){
    // Entrega la informacion de la tabla que se le solicite en Valor
    return JSON.parse(IApp.getValores(Valor))  // eslint-disable-line
  },
  enviarDataOffline(oBody) {
  // se recibe la data como objeto JSON y se debe pasar como sting
    IApp.onGuardarDataOffline(JSON.stringify(oBody)) // eslint-disable-line
  }, 
  enviarFotosOffline(fFile){
    IApp.onIniciarCargarFotos(fFile) // eslint-disable-line
  },
  enviarFirma(sFirma){
    IApp.onGuardarFirma(sFirma) // eslint-disable-line
  },
  // este metodo es con el cual administro el metodo de envio al Celular segun el tipo 
  registro_OK(){
    IApp.onNotificarRegistroExitoso() // eslint-disable-line
  },
  onLeerQR(){
    IApp.onLeerQR() // eslint-disable-line
  },
  getIMEI(){
    return IApp.getImei() // eslint-disable-line
  },
  getSubDominio(){
    return IApp.getSubDominio() // eslint-disable-line
  },
  Notificacion_token_error(){
    IApp.onNotificarErorToken() // eslint-disable-line
  },
  cancel_Form(){
    //validamos que la variable existe teniendo encuenta que no siempre se abre desde la apk
    if(typeof IApp !== "undefined"){
      IApp.onNotificarFormularioCancelado() // eslint-disable-line
    }
  },
  getIdFormulario(){
    if(typeof IApp !== "undefined"){
      if (typeof IApp.getIdFormulario === 'function') {
        return  IApp.getIdFormulario();
      }else{
        return 0
      }
    }else{
      return 0
    }
  },
  onGuardarLog(sLog){
    return IApp.onGuardarLog(sLog) // eslint-disable-line
  },

}