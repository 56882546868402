<template>
   <v-dialog
      v-model="isLecturaQR"
      fullscreen
      transition="dialog-bottom-transition"
      class="d-flex justify-center align-center "
      persistent 
    >
  </v-dialog>
</template>

<script>
import APPutils from '@/utils/APPutils.js'
export default {
  name: 'LecturaQR',
  components: {
  },
	props: {
      activo: {
        type: Boolean,
        default: false
      },
			onLecturaCompletada: Function
    },
  data () {
		return {
      camera: 'off',
      isLecturaQR: false,
    }
	},
	mounted() {
		this.onLeerQR();
	},
  created(){
    vm=this;
  },
  methods: {
    onGuardarQR (sCodigoQR) {
      if(sCodigoQR!=undefined){
        this.onLecturaCompletada(sCodigoQR)
        this.$emit('cerrarQR');
      }
    },
    onLeerQR(){
      if(typeof IApp !== 'undefined'){
        APPutils.onLeerQR();
      }
    },
    onCerrar(){
      this.$emit('cerrarQR');
    }
  }
}
var vm = null;

window.onObtenerQR = function(sCodigoQR) {
  vm.onGuardarQR(sCodigoQR);
}

window.onCancelarCamara = function() {
  vm.onCerrar();
}


</script>
<style lang="scss" scoped>
.algo{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: .5);
}
.btn-cerrar{
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
}
.btn-linterna{
  font-size: 70px !important;
}
.linterna{
  height: 30vh !important;
  position: absolute;
  z-index: 1;
}
</style>